import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { titleise } from 'utils'

interface Props {
  title?: string
  children: React.ReactNode
}

const CardContainer = styled.div`
  padding-left: 0;
  padding-right: 0;
`


export default function CardCustomSubsection({ title, children }: Props): ReactElement {
  return (
    <CardContainer className="Polaris-Card__Section">
      {title ? <div className="Polaris-Card__SectionHeader"><h3 aria-label={title} className="Polaris-Subheading">{title}</h3></div> : null}
      {children}
    </CardContainer>
  )
}
