import { HSBAColor } from '@shopify/polaris'
import dynamic from 'next/dynamic'
import React, { ReactElement } from 'react'
import { BadgeStyle, SVGIconProps } from 'types'
import { hsbaToHsla } from 'utils';

interface Props {
  iconName: string
  colour: HSBAColor
  marginLeft: number
  marginRight: number
  style: BadgeStyle
  size: number
}

export default function TrustBadge({
  iconName,
  colour,
  marginLeft,
  marginRight,
  style,
  size
}: Props): ReactElement {

  const Icon = React.useMemo(() => dynamic<SVGIconProps>(() => import(`icons/${iconName}.svg`)), [])

  const hslaColor = hsbaToHsla(colour);
  const hslaBadgeColor = style !== "card_original" && style !== "icon_original" ? `hsla(
    ${hslaColor.hue},
    ${hslaColor.saturation * 100}%,
    ${hslaColor.lightness * 100}%,
    ${hslaColor.alpha * 100}%
  )
  ` : null

  return (
      <Icon style={{ fill: hslaBadgeColor || '', marginLeft: `${marginLeft}px`, marginRight: `${marginRight}px` }} width={size} />
  )
}
