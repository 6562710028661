import React from "react";
import { AppSettings } from "types";
import { hsbaToHsla } from "utils";

export default React.memo(function HeaderPreview({
  ...textSettings
}: AppSettings["textSettings"]) {
  const textFormatArr: string[] = [];
  if (textSettings.format.includes("bold")) {
    textFormatArr.push("font-weight: bold");
  }
  if (textSettings.format.includes("italic")) {
    textFormatArr.push("font-style: italic");
  }
  if (textSettings.format.includes("underline")) {
    textFormatArr.push("text-decoration: underline");
  }
  const hslaColor = hsbaToHsla(textSettings.colour.hsba);

  return (
    <div
      style={{
        margin: `${textSettings.margins.top}px ${textSettings.margins.right}px ${textSettings.margins.bottom}px ${textSettings.margins.left}px`,
      }}
    >
      <style jsx>{`
        .HeaderText {
          line-height: 1.3;
          color: hsla(
            ${hslaColor.hue},
            ${hslaColor.saturation * 100}%,
            ${hslaColor.lightness * 100}%,
            ${hslaColor.alpha * 100}%
          );
          font-size: ${textSettings.size}px;
          ${textFormatArr.join(";")};
          text-align: ${textSettings.alignment};
          font-family: ${textSettings.fontFamily};
        }
      `}</style>
      <p className={"HeaderText"}>{textSettings.value}</p>
    </div>
  );
});
