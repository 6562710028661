import React, { useContext, useState } from "react";
import {
  TextField,
  Card,
  Icon,
  Button,
  TextContainer,
  FormLayout,
  Tooltip,
  Link,
  TextStyle,
  Checkbox,
  Toast,
  Stack,
} from "@shopify/polaris";
import { ClipboardMinor, QuestionMarkMajor } from "@shopify/polaris-icons";
import { titleise } from "utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TrustBadgesDispatch } from "reducer";
import { useAmplitude } from "react-amplitude-hooks";
import { useRouter } from "next/router";

interface PlacementSettingsProps {
  isAutoInsertEnabled: boolean;
}

export default React.memo(function PlacementSettings({
  isAutoInsertEnabled,
}: PlacementSettingsProps) {
  const dispatch = useContext(TrustBadgesDispatch);
  const { logEvent } = useAmplitude();
  const [copyToast, setCopyToast] = useState(false);
  const router = useRouter();
  const shopOrigin = router.query.shop as string;

  const handleChange = async () => {
    dispatch({
      type: "toggleIsAutoInsertEnabled",
    });
  };

  const handleCopyToClipboard = () => {
    logEvent("copy custom placement");
    setCopyToast(true);
  };

  const toastCopyMarkup = copyToast && (
    <Toast
      content="Copied to clipboard"
      onDismiss={() => setCopyToast(false)}
    />
  );

  return (
    <Card title="Placement Settings" sectioned>
      <FormLayout>
        <Checkbox
          label={
            <Stack spacing={"loose"} distribution={"fill"}>
              <TextStyle>Display under the "Add to cart" button</TextStyle>
              <Tooltip
                content={`This option will automatically display the widget under the "Add To Cart" button on every page`}
              >
                <Icon source={QuestionMarkMajor} />
              </Tooltip>
            </Stack>
          }
          checked={isAutoInsertEnabled}
          onChange={handleChange}
        />
        <TextContainer>
          To display{" "}
          <TextStyle variation="strong">
            {titleise(process.env.NEXT_PUBLIC_APP_NAME as string)}
          </TextStyle>{" "}
          in a custom location, place the following code inside the{" "}
          <Link
            onClick={() => logEvent("click template link")}
            url={`https://${shopOrigin}/admin/themes/current/?key=templates`}
            external={true}
          >
            template file.
          </Link>
        </TextContainer>
        <TextField
          label=""
          readOnly
          type="text"
          value={`<div class="${(process.env.NEXT_PUBLIC_APP_NAME as string).toLowerCase().split(" ").join("-")}"></div>`}
          connectedRight={
            <Tooltip content="Copy to clipboard">
              <CopyToClipboard text={`<div class="${(process.env.NEXT_PUBLIC_APP_NAME as string).toLowerCase().split(" ").join("-")}"></div>`}>
                <Button onClick={handleCopyToClipboard} icon={ClipboardMinor} />
              </CopyToClipboard>
            </Tooltip>
          }
        />
        {toastCopyMarkup}
      </FormLayout>
    </Card>
  );
});
