import React from "react";
import { denormaliseIconName } from "utils";
import { AppSettings } from "types";
import styled, { css } from "styled-components";
import TrustBadge from "./components/TrustBadge";

interface BadgeContainerProps {
  readonly alignment: React.CSSProperties["justifyContent"];
  readonly margins: AppSettings["textSettings"]["margins"];
}

const BadgeContainer = styled.div<BadgeContainerProps>`
  ${(props) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${props.alignment};
    margin: ${props.margins.top}px ${props.margins.right}px
      ${props.margins.bottom}px ${props.margins.left};
  `}
`;

export default React.memo(function BadgesPreview({
  alignment,
  colour,
  selectedBadges,
  size,
  spacing,
  style,
  margins,
}: AppSettings["badgeSettings"]) {
  const denormalisedIconNames = selectedBadges.map((badge) =>
    denormaliseIconName(badge, style)
  );
  let flexBadgeAlignment = "center";
  let marginRight = 0;
  let marginLeft = 0;
  if (alignment === "left") {
    flexBadgeAlignment = "flex-start";
    marginRight = spacing;
  } else if (alignment === "center") {
    flexBadgeAlignment = "center";
    marginLeft = spacing;
    marginRight = spacing;
  } else if (alignment === "right") {
    flexBadgeAlignment = "flex-end";
    marginLeft = spacing;
  }

  return (
    <>
      <BadgeContainer alignment={flexBadgeAlignment} margins={margins}>
        {denormalisedIconNames.map((iconName) => (
          <TrustBadge
            size={size}
            style={style}
            key={iconName}
            iconName={iconName}
            colour={colour.hsba}
            marginLeft={marginLeft}
            marginRight={marginRight}
          />
        ))}
      </BadgeContainer>
    </>
  );
});
