import React, { DOMAttributes, ReactElement, useRef, useState } from 'react'
import { Button, ButtonProps } from '@shopify/polaris'

interface ButtonExtProps {
  onMouseEnter?(): void
  onMouseLeave?(): void
  onBlur?(e: React.FocusEvent<HTMLButtonElement>): void
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void
  style?: React.CSSProperties
  children: React.ReactNode
  primary?: boolean
}


export default function ButtonExt({ onMouseEnter, onMouseLeave, style, children, ...rest }: ButtonExtProps): ReactElement {

  const handleMouseEnter = () => {
    if (typeof onMouseEnter === "function") {
      onMouseEnter()
    }
  }

  const handleMouseLeave = () => {
    if (typeof onMouseLeave === "function") {
      onMouseLeave()
    }
  }

  return (
    <div>
      <button
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={rest.onClick}
        onBlur={rest.onBlur}
        className={`Polaris-Button Polaris-Button--newDesignLanguage ${rest.primary ? "Polaris-Button--primary" : ''} Polaris-Button--iconOnly`}
        type="button"
      >
        <span className="Polaris-Button__Content">
          <span className="Polaris-Button__Icon">{children}</span>
        </span>
      </button>
    </div>
  )
}
