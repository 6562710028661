import React from "react";
import {
  Card,
  FormLayout,
  TextField,
  Button,
  ButtonGroup,
} from "@shopify/polaris";
import { EditMajor } from "@shopify/polaris-icons";
import CardOriginalPreview from "icons/card_preview_original.svg";
import CardMonoPreview from "icons/card_preview_mono.svg";
import IconMonoPreview from "icons/icon_preview_mono.svg";
import IconOriginalPreview from "icons/icon_preview_original.svg";
import { Label } from "components";

export default function BasicSettingsSkeleton() {
  return (
    <Card.Section title="Basic">
      <FormLayout>
        <TextField disabled label="Header text" type="text" />
        <Button disabled primary icon={EditMajor} fullWidth>
          Select badges
        </Button>
        <Label name="Badge style">
          <ButtonGroup segmented fullWidth>
            <Button
              disabled
              icon={
                <CardOriginalPreview
                  width={35}
                  height={35}
                  fill="currentColor"
                />
              }
            />
            <Button
              disabled
              icon={
                <CardMonoPreview width={35} height={35} fill="currentColor" />
              }
            />
            <Button
              disabled
              icon={
                <IconOriginalPreview
                  width={23}
                  height={35}
                  fill="currentColor"
                />
              }
            />
            <Button
              disabled
              icon={
                <IconMonoPreview width={23} height={35} fill="currentColor" />
              }
            />
          </ButtonGroup>
        </Label>
        <Label name="Animation">
          <ButtonGroup fullWidth>
            <Button disabled icon={<span>None</span>} />
            <Button disabled icon={<span>Fade</span>} />
            <Button disabled icon={<span>Fly</span>} />
            <Button disabled icon={<span>Scale</span>} />
          </ButtonGroup>
        </Label>
        <Button
          disabled
          fullWidth
          ariaControls="basic-collapsible"
          disclosure={"down"}
        >
          Manage badges
        </Button>
      </FormLayout>
    </Card.Section>
  );
}
