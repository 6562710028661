import React from 'react'
import {Card, SkeletonBodyText} from '@shopify/polaris'

export default function PreviewCardSkeleton() {
  return (
    <Card sectioned>
      <SkeletonBodyText />
    </Card>
  )
}
