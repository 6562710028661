import React, { useContext } from "react";
import { denormaliseIconName, normaliseIconName } from "utils";
import { TrustBadgesDispatch } from "reducer";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { BadgeStyle } from "types";
import { SortableList } from "./components";
import "./Sortable.module.css";
interface Props {
  badgeStyle: BadgeStyle;
  selectedBadges: string[];
}

export default function Sortable({ badgeStyle, selectedBadges }: Props) {
  const dispatch = useContext(TrustBadgesDispatch);

  const denormalisedIconNames = selectedBadges.map((badge) =>
    denormaliseIconName(badge, badgeStyle)
  );

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }
    const newSelectedBadges = Array.from(selectedBadges);
    newSelectedBadges.splice(source.index, 1);
    newSelectedBadges.splice(
      destination.index,
      0,
      normaliseIconName(draggableId)
    );
    dispatch({
      type: "modifySelectedBadges",
      payload: {
        value: newSelectedBadges,
      },
    });
  };

  const handleRemoveBadge = (iconName: string) => {
    const normalisedIconName = normaliseIconName(iconName);
    const newSelectedBadges = Array.from(selectedBadges);
    const iconIdx = newSelectedBadges.indexOf(normalisedIconName);
    newSelectedBadges.splice(iconIdx, 1);
    dispatch({
      type: "modifySelectedBadges",
      payload: { value: newSelectedBadges },
    });
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <SortableList
        onRemoveBadge={handleRemoveBadge}
        iconNames={denormalisedIconNames}
      />
    </DragDropContext>
  );
}
