import React, { useContext, useState } from "react";
import styles from "./PreviewCard.module.css";
import { Tooltip, Card, Button, ButtonGroup, Layout } from "@shopify/polaris";
import { DesktopMajor, MobileMajor, ViewMajor } from "@shopify/polaris-icons";
import { HeaderPreview, BadgesPreview } from "./components";
import { AnimationEnum, AppSettings, ContextSettings, IOnboarding } from 'types'
import { useGroupSelect, usePreviewUrl } from "utils";
import { withAxiosAuth } from "utils/fetch"
import { useSpring, a, to, config, animated } from 'react-spring'
import * as easings from 'd3-ease'
import styled, { css } from 'styled-components'
import { useAppBridge } from "@shopify/app-bridge-react";
import { TrustBadgesDispatch } from "reducer";
import useSWR, { mutate } from "swr";
import { useRouter } from "next/router";
import { useAmplitude } from "react-amplitude-hooks";

interface ContainerProps {
  readonly isDirty: boolean
}

const Container = styled.div<ContainerProps>`
  position: sticky;
  top: ${props => props.isDirty ? 5.6 : 0}rem;
`

const SideActions = styled.div`
  position: relative;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TrustBadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface PreviewCardProps extends AppSettings {
  isEnabled: ContextSettings["isEnabled"]
  isDirty: ContextSettings["isDirty"]
}

export default function PreviewCard({
  animation,
  textSettings,
  badgeSettings,
  isEnabled,
  isDirty,
}: PreviewCardProps) {
  const previewUrl = usePreviewUrl()
  const { logEvent } = useAmplitude()
  const app = useAppBridge()
  const { data: onboardingData } = useSWR<IOnboarding>('/api/onboarding', url => withAxiosAuth(app).get(url))

  const [styleProps, setStyleProps] = useState({})

  const [propsFade, animateFade] = useSpring(() => ({
    to: { opacity: 1 },
    from: { opacity: 0 },
  }), []);

  const [propsFly, animateFly] = useSpring(() => ({
    to: { t: 1, x: 0, y: 0 },
    from: { t: 0, x: 0, y: 30 },
  }))

  const [propsScale, animateScale] = useSpring(() => ({
    to: { t: 1 },
    from: { t: 0 },
    config: { duration: 500, easing: easings.easeCubicInOut }
  }));

  const [propsBlur, animateBlur] = useSpring(() => ({
    to: { opacity: 1, blur: 0 },
    from: { opacity: 0, blur: 1 },
  }));



  const animate = (value: AnimationEnum): any => {
    let transform;
    switch (value) {
      case "none":
        setStyleProps({})
        break;
      case "fade":
        animateFade({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 500 } })
        setStyleProps(propsFade)
        break
      case "fly":
        transform = to([propsFly.t, propsFly.x, propsFly.y], (t, x, y) => `translate(${(1 - t) * x}px, ${(1 - t) * y}px)`)
        animateFly({ from: { t: 0, x: 0, y: 30 }, to: { t: 1, x: 0, y: 0 }, config: { duration: 500 } })
        setStyleProps({ opacity: propsFly.t as any, transform })
        break
      case "blur":
        animateBlur({ from: { opacity: 0, blur: 1 }, to: { opacity: 1, blur: 0 }, config: { duration: 500 } })
        setStyleProps({ opacity: propsBlur.opacity as any, filter: propsBlur.blur.to(blur => `blur(${blur * 5}px)`) as any })
        break;
      case "scale":
        transform = to([propsScale.t], (t) => `scale(${(t * 0.5) + 0.5})`)
        animateScale({ from: { t: 0 }, to: { t: 1 }, config: { duration: 500, easing: easings.easeCubicInOut } })
        setStyleProps({ opacity: propsScale.t as any, transform, display: "block" })
        break;
      default:
        throw new Error("Animation not recognised")
    }
  }

  React.useEffect(() => {
    if (animation.key) {
      animate(animation.animation)
    }
  }, [animation.key])

  const [selectedPreview, handleSelectedPreview] = useGroupSelect<"mobile" | "desktop">({ isMulti: false, defaultValue: ["desktop"] })
  const [props, animateWidth] = useSpring(() => ({ width: `100%`, config: config.default }), [])

  const positionStyle = {};

  const handleResize = (value: "mobile" | "desktop") => {
    animateWidth({ width: value === "desktop" ? `100%` : `65%` })
  }

  const handlePreviewChange = (value: "mobile" | "desktop") => {
    logEvent("change preview", { value })
    handleSelectedPreview(value)
    handleResize(value)
  }

  const handlePreviewClick = async () => {
    if (onboardingData && !onboardingData.isWidgetPreviewed) {
      const newData = { ...onboardingData, isWidgetPreviewed: true }
      logEvent("click preview button")
      mutate('/api/onboarding', newData, false)
      mutate('/api/onboarding', withAxiosAuth(app).post('/api/onboarding', newData))
    }
  }

  return (
    <Container isDirty={isDirty} className={"Polaris-Layout__Section Polaris-Layout__Section--oneHalf"}>
      <TrustBadgesContainer>
        <SideActions>
          <ButtonGroup segmented>
            <Button primary={selectedPreview.includes("mobile")} onClick={() => handlePreviewChange("mobile")} icon={MobileMajor}>Mobile</Button>
            <Button primary={selectedPreview.includes("desktop")} onClick={() => handlePreviewChange("desktop")} icon={DesktopMajor}>Desktop</Button>
          </ButtonGroup>
        </SideActions>
        <a.div style={props}>
          <div className={`${styles.PreviewCard} Polaris-Card`} style={positionStyle}>
            <a.div style={styleProps} className="Polaris-Card__Section TrustBadgeWrapper">
              <HeaderPreview {...textSettings} />
              <BadgesPreview {...badgeSettings} />
            </a.div>
          </div>
        </a.div>
        <SideActions>
          {!isEnabled ? (
            <Tooltip content={"Enable widget to view it on your store"}>
              <Button monochrome disabled={true} onClick={handlePreviewClick} external url={previewUrl} icon={ViewMajor}>View on your store</Button>
            </Tooltip>
          ) : <Button monochrome onClick={handlePreviewClick} external url={previewUrl} icon={ViewMajor}>View on your store</Button>}
        </SideActions>
      </TrustBadgesContainer>
    </Container>
  );
}
