import { createContext, Dispatch } from "react";
import { AppState, Action, AppSettings } from 'types'
import { dirtyRegister } from 'utils'
import _ from 'lodash'

const initialContextSettings = {
  dirtyList: [],
  isDirty: false,
  isEnabled: false,
  isAutoInsertEnabled: false,
  onboarding: {
    isDisplayCustomised: false,
    isWidgetPreviewed: false,
    isOnboardingClosed: false
  },
}

const initialAppSettings: AppState["appSettings"] = {
  animation: {
    key: 0,
    animation: "none"
  },
  textSettings: {
    value: '',
    alignment: 'center',
    colour: {
      hsba: {
        hue: 0,
        saturation: 0,
        brightness: 0,
        alpha: 0
      }
    },
    fontFamily: 'inherit',
    margins: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    },
    format: [],
    size: 0
  },
  badgeSettings: {
    alignment: "center",
    colour: {
      hsba: {
        hue: 0,
        saturation: 0,
        brightness: 0,
        alpha: 0
      }
    },
    selectedBadges: [],
    margins: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    },
    size: 0,
    spacing: 0,
    style: "card_original"
  },
}

export const initialState: AppState = {
  contextSettings: {
    ...initialContextSettings
  },
  appSettings: {
   ...initialAppSettings
  },
  initialState: {
    contextSettings: {
      ...initialContextSettings
    },
    appSettings: {
      ...initialAppSettings
    }
  }
};

export function reducer(state: AppState, action: Action): AppState {
  const prevState = { ...state };
  const dirtyList = prevState.contextSettings.dirtyList;
  let key: string;
  switch (action.type) {
    case "modifySelectedBadges":
      key = "selectedBadges"
      prevState.appSettings.badgeSettings["selectedBadges"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.badgeSettings["selectedBadges"] }, action.payload.value)
      return prevState
    case "modifyBadgeSize":
      key = "size"
      prevState.appSettings.badgeSettings["size"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.badgeSettings["size"] }, action.payload.value)
      return prevState
    case "modifyBadgeColor":
      key = "colour"
      prevState.appSettings.badgeSettings["colour"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.badgeSettings["colour"] }, action.payload.value)
      return prevState
    case "modifyBadgeMargins":
      key = "margins"
      prevState.appSettings.badgeSettings["margins"] = { ...prevState.appSettings.badgeSettings["margins"], ...action.payload.value }
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.badgeSettings["margins"] }, action.payload.value)
      return prevState
    case "modifyBadgeAlignment":
      key = "alignment"
      prevState.appSettings.badgeSettings["alignment"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.badgeSettings["alignment"] }, action.payload.value)
      return prevState
    case "modifyBadgeSpacing":
      key = "spacing"
      prevState.appSettings.badgeSettings["spacing"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.badgeSettings["spacing"] }, action.payload.value)
      return prevState
    case "modifyBadgeStyle":
      key = "style"
      prevState.appSettings.badgeSettings["style"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.badgeSettings["style"] }, action.payload.value)
      return prevState
    case "modifyTextColor":
      key = "colour"
      prevState.appSettings.textSettings["colour"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.textSettings["colour"] }, action.payload.value)
      return prevState
    case "modifyTextValue":
      key = "value"
      prevState.appSettings.textSettings["value"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.textSettings["value"] }, action.payload.value)
      return prevState
    case "modifyTextSize":
      key = "size"
      prevState.appSettings.textSettings["size"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.textSettings["size"] }, action.payload.value)
      return prevState
    case "modifyTextMargins":
      key = "margins"
      prevState.appSettings.textSettings["margins"] = { ...prevState.appSettings.textSettings["margins"], ...action.payload.value }
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.textSettings["margins"] }, action.payload.value)
      return prevState
    case "modifyFontFamily":
      key = "fontFamily"
      prevState.appSettings.textSettings["fontFamily"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.textSettings["fontFamily"] }, action.payload.value)
      return prevState
    case "modifyTextFormat":
      key = "format"
      prevState.appSettings.textSettings["format"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.textSettings["format"] }, action.payload.value)
      return prevState
    case "modifyTextAlignment":
      key = "alignment"
      prevState.appSettings.textSettings["alignment"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value>(dirtyList, { [key]: prevState.initialState.appSettings.textSettings["alignment"] }, action.payload.value)
      return prevState
    case "modifyAnimation":
      key = "animation"
      prevState.appSettings["animation"] = action.payload.value
      prevState.contextSettings.dirtyList = dirtyRegister<typeof action.payload.value.animation>(dirtyList, { [key]: prevState.initialState.appSettings["animation"].animation }, action.payload.value.animation)
      return prevState
    case "toggleIsAutoInsertEnabled":
      key = "isAutoInsertEnabled"
      const newIsAutoInsertEnabled = !prevState.contextSettings["isAutoInsertEnabled"]
      prevState.contextSettings["isAutoInsertEnabled"] = newIsAutoInsertEnabled
      prevState.contextSettings.dirtyList = dirtyRegister<typeof newIsAutoInsertEnabled>(dirtyList, { [key]: prevState.initialState.contextSettings["isAutoInsertEnabled"] as boolean }, newIsAutoInsertEnabled)
      return prevState
    case "modifySettings":
      prevState.contextSettings.isDirty = action.payload.isDirty;
      return prevState;
    case "toggleIsEnabled":
      prevState.contextSettings.isEnabled = !prevState.contextSettings
        .isEnabled;
      // prevState.contextSettings.onboarding.isWidgetEnabled = true
      return prevState;
    case "toggleOnboarding":
      const { isDisplayCustomised, isOnboardingClosed, isWidgetPreviewed } = action.payload.value
      if (typeof isWidgetPreviewed !== "undefined") {
        prevState.contextSettings.onboarding.isWidgetPreviewed = isWidgetPreviewed
      }
      if (typeof isDisplayCustomised !== "undefined") {
        prevState.contextSettings.onboarding.isDisplayCustomised = isDisplayCustomised
      }
      if (typeof isOnboardingClosed !== "undefined") {
        prevState.contextSettings.onboarding.isOnboardingClosed = isOnboardingClosed
      }
      return prevState;
    case "save":
      prevState.appSettings = action.payload.appSettings;
      prevState.contextSettings.isAutoInsertEnabled = action.payload.contextSettings.isAutoInsertEnabled
      // We could made prevState.initialAppSettings = action.payload.appSettings. However, this would create same reference to initialAppSettings object,
      // which would mean that whenever we update appSettings, initialAppSettings would also be updated, which is a behaviour we don't not want. That is why
      // we create a new object using the spread operator
      prevState.initialState.appSettings = _.cloneDeep(action.payload.appSettings);
      prevState.initialState.contextSettings = { ...action.payload.contextSettings }
      prevState.contextSettings.dirtyList = []; // Cleanup the dirtyList
      prevState.contextSettings.isDirty = false;
      return prevState;
    case "setup":
      prevState.contextSettings.isAutoInsertEnabled = action.payload.isAutoInsertEnabled;
      prevState.contextSettings.onboarding = action.payload.onboarding;
      prevState.contextSettings.isEnabled = action.payload.isEnabled;
      prevState.appSettings = action.payload.appSettings;
      prevState.initialState.appSettings = _.cloneDeep(action.payload.appSettings);
      prevState.initialState.contextSettings.isAutoInsertEnabled = action.payload.isAutoInsertEnabled
      return prevState;
    case "reset":
      prevState.contextSettings.dirtyList = []; // Cleanup the dirtyList
      prevState.contextSettings.isDirty = false;
      prevState.appSettings = _.cloneDeep(state.initialState.appSettings);
      prevState.appSettings.animation.key = state.appSettings.animation.key // We keep the key of the last render to prevent rerender when discarding the changes
      prevState.contextSettings.isAutoInsertEnabled = state.initialState.contextSettings.isAutoInsertEnabled
      return prevState;
    default:
      throw new Error(`Unhandled action: ${action}`);
  }
}

export const TrustBadgesDispatch = createContext<Dispatch<Action>>(null as any);
