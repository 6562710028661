import { names as nameHexMap } from "./colorMap";
import { rgbToHex, HSBColor, rgbToHsb } from "@shopify/polaris";

const SIX_DIGIT_HEX = "[0-9A-F]{6}$";
const THREE_DIGIT_HEX = "[0-9A-F]{3}$";
const HEX_REGEX = new RegExp(
  `(^#${SIX_DIGIT_HEX})|(^#${THREE_DIGIT_HEX})`,
  "i"
);
const HASHLESS_HEX_REGEX = new RegExp(
  `(^${SIX_DIGIT_HEX})|(^${THREE_DIGIT_HEX})`,
  "i"
);
const RGB_STRING_TO_HEX_REGEX = /^rgb[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i;

export function isColorName(value: string) {
  return !!nameHexMap[value];
}

export function expandHex(hex: string) {
  if (hex.length === 4) {
    return `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }
  return hex;
}

export function hexToRgb(color: string) {
  if (color.length === 4) {
    const repeatHex = (hex1: number, hex2: number) =>
      color.slice(hex1, hex2).repeat(2);
    const red = parseInt(repeatHex(1, 2), 16);
    const green = parseInt(repeatHex(2, 3), 16);
    const blue = parseInt(repeatHex(3, 4), 16);

    return { red, green, blue };
  }

  const red = parseInt(color.slice(1, 3), 16);
  const green = parseInt(color.slice(3, 5), 16);
  const blue = parseInt(color.slice(5, 7), 16);

  return { red, green, blue };
}

export function isHexString(value: string) {
  return HEX_REGEX.test(value);
}

export function normalizeColorString(value: string) {
  return value.toLowerCase().replace(/\s/g, "");
}

export function isHashlessHex(value: string) {
  return HASHLESS_HEX_REGEX.test(value);
}
export function nameToHex(value: string) {
  return expandHex(`#${nameHexMap[value]}`);
}

export function rgbStringToHex(value: string) {
  const rgb = normalizeColorString(value).match(RGB_STRING_TO_HEX_REGEX) || [
    undefined,
    "0",
    "0",
    "0"
  ];
  return rgbToHex({
    red: parseInt(rgb[1]!, 10),
    green: parseInt(rgb[2]!, 10),
    blue: parseInt(rgb[3]!, 10)
  });
}

export function isRgbString(value: string) {
  return /rgb\(\s*(0*((25[0-5]|2[0-4]\d|1\d{1,2}|\d\d?)\s*,\s*?)){2}(0*(25[0-5]|2[0-4]\d|1\d{1,2}|\d\d?))\s*,?\s*([01]\.?\d*?)?\s*\)/i.test(
    value
  );
}

export function hexToHsb(hex: string): HSBColor {
  return rgbToHsb(hexToRgb(hex));
}

export function coerceToValidUserInput(value: string) {
  const normalizedValue = normalizeColorString(value);

  if (isHexString(normalizedValue)) return expandHex(normalizedValue);
  else if (isHashlessHex(value)) return expandHex(`#${expandHex(value)}`);
  else if (isRgbString(normalizedValue)) return rgbStringToHex(normalizedValue);
  else if (isColorName(normalizedValue)) return nameToHex(normalizedValue);
}
