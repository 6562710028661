import React, { useEffect, useState } from "react";
import {
  Card,
  ChoiceList,
  Filters,
  Stack,
  Modal,
  AppliedFilterInterface,
} from "@shopify/polaris";
import { CardBadge } from "components";
import { BadgesEmptyState } from "./components";
import { normaliseIconName, getIconInfo } from "utils";
import dynamic from "next/dynamic";
import { SVGIconProps } from "types";

function isEmpty(value: string[] | string) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
}

type BadgeType = "Payment icons" | "Trust icons";

interface FiltersExtProps {
  selectedBadges: string[];
  onCardBadgeChange(iconName: string, checked: boolean): void;
  previewIcons: string[];
}

interface IconList {
  iconName: string;
  category: "payment" | "trust";
  caption: string;
  checked: boolean;
}

function FiltersExt({
  selectedBadges,
  previewIcons,
  onCardBadgeChange,
}: FiltersExtProps) {
  const [badgeType, setBadgeType] = useState<[BadgeType] | null>(null);
  const [queryValue, setQueryValue] = useState<string | undefined>();
  const [initialIconList, setInitialIconList] = useState<IconList[]>([]);
  const [modifiedIconList, setModifiedIconList] = useState<IconList[]>([]);

  useEffect(() => {
    const iconList: IconList[] = [];
    const selectedIcons = selectedBadges.slice();
    previewIcons.map((iconName) => {
      const cleanedIconName = iconName.replace(".svg", "");
      const { category, caption } = getIconInfo(cleanedIconName);
      iconList.push({
        iconName: cleanedIconName,
        category,
        caption,
        checked: selectedIcons.includes(normaliseIconName(cleanedIconName)),
      });
    });

    setInitialIconList(iconList);
    setModifiedIconList(iconList);
  }, []);

  const handleCardBadgeChange = (iconName: string, checked: boolean): void => {
    const iconList = [...initialIconList];
    const iconIdx = iconList.findIndex(
      (iconObj) => iconObj.iconName === iconName
    );
    iconList[iconIdx].checked = checked;
    setInitialIconList(iconList);
    onCardBadgeChange(iconName, checked);
  };

  /**
   * @param value Query
   * @param iconList List of objects to perform the filter. Objects must contain a caption property.
   */
  const handleFilteredQuery = (value: string, iconList: IconList[]) => {
    // We return the icons that starts with the "value"'s value parameter
    return iconList.filter((iconObj) =>
      iconObj.caption.toLowerCase().includes(value.toLowerCase())
    );
  };

  const handleQueryChange = (value: string) => {
    let iconList = [...initialIconList];
    if (badgeType) {
      console.log(badgeType);
      iconList = handleBadgeType(badgeType[0]);
    }
    setQueryValue(value);
    setModifiedIconList(handleFilteredQuery(value, iconList));
  };

  const handleBadgeType = (value: BadgeType) => {
    let iconList: IconList[];
    if (value === "Payment icons") {
      iconList = initialIconList.filter(
        (iconObj) => iconObj.category === "payment"
      );
    } else if (value === "Trust icons") {
      iconList = initialIconList.filter(
        (iconObj) => iconObj.category === "trust"
      );
    } else {
      iconList = [];
      throw new Error("Icon set not available");
    }
    return iconList;
  };

  const handleChangeBadgeType = (value: [BadgeType]) => {
    let iconList = handleBadgeType(value[0]);
    if (queryValue) {
      iconList = handleFilteredQuery(queryValue, iconList);
    }
    setModifiedIconList(iconList);
    setBadgeType(value);
  };

  const handleBadgeTypeRemove = () => {
    let iconList = [...initialIconList];
    if (queryValue) {
      iconList = handleFilteredQuery(queryValue, iconList);
    }
    setModifiedIconList(iconList);
    setBadgeType(null);
  };

  const handleQueryClear = () => {
    let iconList = [...initialIconList];
    if (badgeType !== null) {
      iconList = handleBadgeType(badgeType[0]);
    }
    setModifiedIconList(iconList);
    setQueryValue(undefined);
  };

  const handleClearAll = () => {
    setModifiedIconList(initialIconList);
    setQueryValue(undefined);
    setBadgeType(null);
  };

  const filters = [
    {
      key: "badgeType",
      label: "Badge type",
      filter: (
        <ChoiceList
          title={"Badge type"}
          titleHidden
          choices={[
            { label: "Payment icons", value: "Payment icons" },
            { label: "Trust icons", value: "Trust icons" },
          ]}
          selected={badgeType || []}
          onChange={handleChangeBadgeType}
          allowMultiple={false}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters: AppliedFilterInterface[] = [];
  if (badgeType && badgeType[0]) {
    const key = "badgeType";
    appliedFilters.push({
      key,
      label: badgeType[0],
      onRemove: handleBadgeTypeRemove,
    });
  }
  return (
    <>
      <Modal.Section>
        <Filters
          queryValue={queryValue}
          queryPlaceholder="Filter badges"
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={handleQueryChange}
          onQueryClear={handleQueryClear}
          onClearAll={handleClearAll}
        />
      </Modal.Section>
      <Modal.Section>
        {modifiedIconList.length == 0 ? (
          <BadgesEmptyState />
        ) : (
          <Stack alignment={"fill"} distribution={"center"}>
            {modifiedIconList.map((iconObj) => {
              return (
                <CardBadge
                  id={iconObj.iconName}
                  key={iconObj.iconName}
                  checked={iconObj.checked}
                  caption={iconObj.caption}
                  onChange={handleCardBadgeChange}
                />
              );
            })}
          </Stack>
        )}
      </Modal.Section>
    </>
  );
}

export default FiltersExt;
