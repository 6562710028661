import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  FormLayout,
  TextField,
  TextContainer,
  ButtonGroup,
  Button,
  Tooltip,
  Icon,
} from "@shopify/polaris";
import { useSpring, animated, to, a } from "react-spring";
import { ModalExt } from "./components";
import { TrustBadgesDispatch } from "reducer";
import { CalendarMajor, AccessibilityMajor, AddCodeMajor, AddImageMajor } from '@shopify/polaris-icons'
import { Accordion, Sortable, ButtonExt, Label } from "components";
import CardOriginalPreview from "icons/card_preview_original.svg";
import CardMonoPreview from "icons/card_preview_mono.svg";
import IconMonoPreview from "icons/icon_preview_mono.svg";
import IconOriginalPreview from "icons/icon_preview_original.svg";
import { BadgeStyle, Animation, AnimationEnum, Margins } from "types";
import { normaliseIconName, useGroupSelect } from "utils";
import * as easings from "d3-ease";
import { useAmplitude } from "react-amplitude-hooks";

interface AnimateProps {
  children: React.ReactElement | string;
  shouldAnimate: boolean;
}

function AnimateFade({ children, shouldAnimate }: AnimateProps) {
  const props = useSpring({
    loop: true,
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 500 },
  });

  if (!shouldAnimate) {
    return <span>{children}</span>;
  }

  return <a.span style={props as any}>{children}</a.span>;
}

function AnimateBlur({ children, shouldAnimate }: AnimateProps) {
  const { opacity, blur } = useSpring({
    loop: true,
    to: { opacity: 1, blur: 0 },
    from: { opacity: 0, blur: 1 },
    config: { duration: 500 },
  });

  if (!shouldAnimate) {
    return <span>{children}</span>;
  }

  return (
    <a.span
      style={{
        opacity: opacity as any,
        filter: blur.to((blur) => `blur(${blur * 5}px)`) as any,
      }}
    >
      {children}
    </a.span>
  );
}

function AnimateFly({ children, shouldAnimate }: AnimateProps) {
  const { t, x, y } = useSpring({
    loop: true,
    to: { t: 1, x: 0, y: 0 },
    from: { t: 0, x: 0, y: 30 },
    config: { duration: 500 },
  });

  if (!shouldAnimate) {
    return <span>{children}</span>;
  }

  const transform = to(
    [t, x, y],
    (t, x, y) => `translate(${(1 - t) * x}px, ${(1 - t) * y}px)`
  );

  return (
    <a.span style={{ opacity: t as any, transform, display: "block" }}>
      {children}
    </a.span>
  );
}

function AnimateScale({ children, shouldAnimate }: AnimateProps) {
  const { t } = useSpring({
    loop: true,
    to: { t: 1 },
    from: { t: 0 },
    config: { duration: 500, easing: easings.easeCubicInOut },
  });

  if (!shouldAnimate) {
    return <span>{children}</span>;
  }

  const transform = to([t], (t) => `scale(${t * 0.5 + 0.5})`);

  return (
    <a.span style={{ opacity: t as any, transform, display: "block" }}>
      {children}
    </a.span>
  );
}

interface BasicSettingsProps {
  textValue: string;
  selectedBadges: string[];
  badgeStyle: BadgeStyle;
  animation: Animation;
  previewIcons: string[]
}

function BasicSettings({
  textValue,
  selectedBadges,
  badgeStyle,
  animation,
  previewIcons
}: BasicSettingsProps) {
  const dispatch = useContext(TrustBadgesDispatch);
  const { logEvent } = useAmplitude();
  const [selectedBadgeStyle, handleSelectedBadgeStyle] = useGroupSelect<
    BadgeStyle
  >({ isMulti: false, defaultValue: [badgeStyle] });
  const [selectedAnimation, handleSelectedAnimation] = useGroupSelect<
    AnimationEnum
  >({ isMulti: false, defaultValue: [animation.animation] });
  const [shouldAnimateFade, setShouldAnimateFade] = useState(false);
  const [shouldAnimateBlur, setShouldAnimateBlur] = useState(false);
  const [shouldAnimateFly, setShouldAnimateFly] = useState(false);
  const [shouldAnimateScale, setShouldAnimateScale] = useState(false);
  const normalisedSelectedBadges = React.useMemo(
    () =>
      selectedBadges.map((selectedBadge) => normaliseIconName(selectedBadge)),
    [selectedBadges]
  );

  const handleTextChange = (value: string) => {
    dispatch({
      type: "modifyTextValue",
      payload: { value },
    });
  };

  React.useEffect(() => {
    dispatch({
      type: "modifyBadgeStyle",
      payload: { value: selectedBadgeStyle[0] },
    });
  }, [selectedBadgeStyle[0]]);

  const handleAnimation = (value: AnimationEnum): void => {
    handleSelectedAnimation(value);
    dispatch({
      type: "modifyAnimation",
      payload: {
        value: {
          key: Date.now(),
          animation: value,
        },
      },
    });
  };

  return (
    <Card.Section title="Basic">
      <FormLayout>
        <TextField
          onChange={handleTextChange}
          value={textValue}
          onBlur={() => logEvent("finish header text", { value: textValue })}
          label="Header text"
          type="text"
        />
        <ModalExt previewIcons={previewIcons} selectedBadges={normalisedSelectedBadges} />
        <Label name={"Badge style"}>
          <ButtonGroup segmented fullWidth>
            <Tooltip content={"Original card"}>
              <Button
                primary={selectedBadgeStyle.includes("card_original")}
                onClick={() => handleSelectedBadgeStyle("card_original")}
                onBlur={() =>
                  logEvent("change style", { style: "card_original" })
                }
                icon={<CardOriginalPreview width={40} height={40} />}
              />
            </Tooltip>
            <Tooltip content={"Monochrome card"}>
              <Button
                primary={selectedBadgeStyle.includes("card_mono")}
                onClick={() => handleSelectedBadgeStyle("card_mono")}
                onBlur={() => logEvent("change style", { style: "card_mono" })}
                icon={<CardMonoPreview width={40} height={40} />}
              />
            </Tooltip>
            <Tooltip content={"Original icon"}>
              <Button
                primary={selectedBadgeStyle.includes("icon_original")}
                onClick={() => handleSelectedBadgeStyle("icon_original")}
                onBlur={() =>
                  logEvent("change style", { style: "icon_original" })
                }
                icon={<IconOriginalPreview width={23} height={40} />}
              />
            </Tooltip>
            <Tooltip content={"Monochrome icon"}>
              <Button
                primary={selectedBadgeStyle.includes("icon_mono")}
                onClick={() => handleSelectedBadgeStyle("icon_mono")}
                onBlur={() => logEvent("change style", { style: "icon_mono" })}
                icon={<IconMonoPreview width={23} height={40} />}
              />
            </Tooltip>
          </ButtonGroup>
        </Label>

        <Label name={"Animation"}>
          <ButtonGroup fullWidth>
            <ButtonExt
              primary={selectedAnimation.includes("none")}
              onBlur={() => logEvent("change animation", { animation: "none" })}
              onClick={() => handleAnimation("none")}
            >
              <span>None</span>
            </ButtonExt>
            <ButtonExt
              onMouseEnter={() => setShouldAnimateFade(true)}
              onMouseLeave={() => setShouldAnimateFade(false)}
              onBlur={() => logEvent("change animation", { animation: "fade" })}
              primary={selectedAnimation.includes("fade")}
              onClick={() => handleAnimation("fade")}
            >
              <AnimateFade shouldAnimate={shouldAnimateFade}>Fade</AnimateFade>
            </ButtonExt>
            <ButtonExt
              onMouseEnter={() => setShouldAnimateFly(true)}
              onMouseLeave={() => setShouldAnimateFly(false)}
              onBlur={() => logEvent("change animation", { animation: "fly" })}
              primary={selectedAnimation.includes("fly")}
              onClick={() => handleAnimation("fly")}
            >
              <AnimateFly shouldAnimate={shouldAnimateFly}>Fly</AnimateFly>
            </ButtonExt>
            <ButtonExt
              onMouseEnter={() => setShouldAnimateBlur(true)}
              onMouseLeave={() => setShouldAnimateBlur(false)}
              onBlur={() => logEvent("change animation", { animation: "blur" })}
              primary={selectedAnimation.includes("blur")}
              onClick={() => handleAnimation("blur")}
            >
              <AnimateBlur shouldAnimate={shouldAnimateBlur}>Blur</AnimateBlur>
            </ButtonExt>
            <ButtonExt
              onMouseEnter={() => setShouldAnimateScale(true)}
              onMouseLeave={() => setShouldAnimateScale(false)}
              onBlur={() =>
                logEvent("change animation", { animation: "scale" })
              }
              primary={selectedAnimation.includes("scale")}
              onClick={() => handleAnimation("scale")}
            >
              <AnimateScale shouldAnimate={shouldAnimateScale}>
                Scale
              </AnimateScale>
            </ButtonExt>
          </ButtonGroup>
        </Label>
        <Accordion
          id="manage-badges-accordion"
          buttonText={"Manage badges"}
          buttonProps={{ fullWidth: true }}
        >
          {selectedBadges.length === 0 ? (
            <Card.Section>
              <TextContainer>There are no badges selected.</TextContainer>
            </Card.Section>
          ) : (
            <Sortable badgeStyle={badgeStyle} selectedBadges={selectedBadges} />
          )}
        </Accordion>
      </FormLayout>
    </Card.Section>
  );
}

export default React.memo(BasicSettings);
