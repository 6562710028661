import React, { ReactElement } from 'react'
import { Stack, Link, Tooltip, Icon } from '@shopify/polaris'
import { Draggable } from 'react-beautiful-dnd'
import { DeleteMinor, DragHandleMinor } from '@shopify/polaris-icons'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { SVGIconProps } from 'types'

interface Props {
  iconName: string
  index: number
  onRemoveBadge(iconName: string): void
}

const TooltipContainer = styled.div`
& > span {
  outline: none;
}
`

export default function SortableItem({ iconName, index, onRemoveBadge }: Props): ReactElement {
  const SvgComponent = React.useMemo(() => dynamic<SVGIconProps>(() => import(`icons/${iconName}.svg`)), [])
  const handleRemoveBadge = (e: React.SyntheticEvent) => {
    onRemoveBadge(e.currentTarget.id)
  }

  return (
    <Draggable draggableId={iconName} index={index}>
      {(provided) => (
        <div className={"Polaris-Card__Section"} {...provided.draggableProps} ref={provided.innerRef}>
          <Stack alignment="center" distribution="equalSpacing" wrap={false}>
            <Stack.Item>
              <div {...provided.dragHandleProps}>
                <TooltipContainer>
                  <Tooltip content={"Drag to move"} preferredPosition="above">
                    <Icon source={DragHandleMinor} />
                  </Tooltip>
                </TooltipContainer>
              </div>
            </Stack.Item>
            <Stack.Item>
              <SvgComponent width={60} height={60} />
            </Stack.Item>
            <Stack.Item>
              <style jsx>{`
                .DeleteTooltip {
                  outline: none
                }
              `}</style>

              <Link id={iconName} onClick={handleRemoveBadge as any}>
                <TooltipContainer>
                  <Tooltip content="Delete" preferredPosition="above">
                    <Icon source={DeleteMinor} />
                  </Tooltip>
                </TooltipContainer>
              </Link>
            </Stack.Item>
          </Stack>
        </div>
      )}
    </Draggable>
  )
}
