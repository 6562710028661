import React, { ReactElement, SVGProps, FC } from 'react'
import { Stack, Card, Icon, Heading, ProgressBar, TextStyle, Button } from '@shopify/polaris'
import { CircleTickMajor } from '@shopify/polaris-icons'

interface Props {
  title: string | React.ReactNode
  isCompleted: boolean
  description: string
  icon: FC<SVGProps<SVGSVGElement>>
}

export default function OnboardingStep({
  title,
  isCompleted,
  description,
  icon
}: Props): ReactElement {
  return (
    <Stack vertical>
      <Card subdued={isCompleted && true} sectioned>
        <Stack vertical>
          <Stack.Item>
            <Stack alignment={"center"}>
              <Stack.Item>
                <Icon
                  color={isCompleted ? "success" : "subdued"}
                  source={isCompleted ? CircleTickMajor : icon}
                ></Icon>
              </Stack.Item>
              <Stack.Item>
                <Heading>{title}</Heading>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <TextStyle variation="subdued">
              {description}
            </TextStyle>
          </Stack.Item>
        </Stack>
      </Card>
      <Stack.Item>
        <ProgressBar
          size={"small"}
          progress={isCompleted ? 100 : 0}
        />
      </Stack.Item>
    </Stack>
  )
}
