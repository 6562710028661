import React, { useState, useContext, useEffect } from "react";
import { Button, FormLayout, Modal, TextField, Toast } from "@shopify/polaris";
import { EditMajor } from "@shopify/polaris-icons";
import { FiltersExt } from "./components";
import { normaliseIconName } from "utils";
import { TrustBadgesDispatch } from "reducer";
import { useAmplitude } from "react-amplitude-hooks";
import { withAxiosAuth } from "utils/fetch";
import { useAppBridge } from "@shopify/app-bridge-react";
import { captureException } from "@sentry/react";

interface ModalExtProps {
  selectedBadges: string[];
  previewIcons: string[];
}

export default React.memo(function ModalExt({
  selectedBadges,
  previewIcons,
}: ModalExtProps) {
  const dispatch = useContext(TrustBadgesDispatch);
  const app = useAppBridge();
  const [active, setActive] = useState(false);
  const [activeBadgeReq, setActiveBadgeReq] = useState(false);
  const [additionalInfoValue, setAdditionalInfoValue] = useState("");
  const [badgeNameValue, setBadgeNameValue] = useState("");
  const [selectedBadgesList, setSelectedBadgesList] = useState(selectedBadges);
  const [badgeReqLoading, setBadgeReqLoading] = useState(false);
  const [badgeReqToast, setBadgeReqToast] = useState(false);
  const { logEvent } = useAmplitude();

  const handleChange = () => {
    setActive(!active);
    setSelectedBadgesList(selectedBadges);
  };

  const handleDone = () => {
    setActive(!active);
    dispatch({
      type: "modifySelectedBadges",
      payload: { value: selectedBadgesList },
    });
  };

  const handleCardBadgeChange = (iconName: string, checked: boolean) => {
    const normalisedIconName = normaliseIconName(iconName);
    if (checked) {
      setSelectedBadgesList((prevSelectedBadges) => [
        ...prevSelectedBadges,
        normalisedIconName,
      ]);
    } else {
      setSelectedBadgesList((prevSelectedBadges) => {
        const newSelectedBadges = [...prevSelectedBadges];
        const iconIdx = newSelectedBadges.findIndex(
          (badge) => badge === normalisedIconName
        );
        newSelectedBadges.splice(iconIdx, 1);
        return newSelectedBadges;
      });
    }
  };

  const activator = (
    <Button onClick={handleChange} primary icon={EditMajor} fullWidth>
      Select badges
    </Button>
  );

  const handleBadgeReqClick = () => {
    logEvent("click badge request");
    setActive(false);
    setActiveBadgeReq(true);
  };

  const handleBadgeReqClose = () => {
    logEvent("close badge request");
    setActiveBadgeReq(false);
    setBadgeNameValue("");
    setAdditionalInfoValue("");
  };

  const handleAdditionalInfoChange = (value: string) => {
    setAdditionalInfoValue(value);
  };

  const handleBadgeNameChange = (value: string) => {
    setBadgeNameValue(value);
  };

  const toastMarkup = badgeReqToast && (
    <Toast content="Request sent" onDismiss={() => setBadgeReqToast(false)} />
  );

  const handleSendBadgeReq = async () => {
    logEvent("send badge request", {
      badgeName: badgeNameValue,
      additionalInfo: additionalInfoValue,
    });
    setBadgeReqLoading(true);
    await withAxiosAuth(app).post("/api/badgeRequest", {
      badgeName: badgeNameValue,
      additionalInformation: additionalInfoValue,
    });
    setBadgeReqLoading(false);
    setBadgeReqToast(true);
    setActiveBadgeReq(false);
    setBadgeNameValue("");
    setAdditionalInfoValue("");
  };

  return (
    <>
      <Modal
        activator={activator}
        open={active}
        large
        onClose={handleChange}
        title="Select trust badges"
        primaryAction={{
          content: "Save",
          onAction: handleDone,
          disabled:
            JSON.stringify(selectedBadgesList) ===
            JSON.stringify(selectedBadges),
        }}
        footer={
          <Button plain onClick={handleBadgeReqClick}>
            Request badge
          </Button>
        }
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleChange,
          },
        ]}
      >
        <FiltersExt
          previewIcons={previewIcons}
          selectedBadges={selectedBadgesList}
          onCardBadgeChange={handleCardBadgeChange}
        />
      </Modal>
      <Modal
        open={activeBadgeReq}
        onClose={() => setActiveBadgeReq(false)}
        title={"Badge request"}
        primaryAction={{
          content: "Request badge",
          loading: badgeReqLoading,
          disabled: !badgeNameValue,
          onAction: handleSendBadgeReq,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleBadgeReqClose,
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <TextField
              value={badgeNameValue}
              onChange={handleBadgeNameChange}
              placeholder={"Visa"}
              label={"Badge name"}
            />
            <TextField
              value={additionalInfoValue}
              label="Additional information"
              placeholder={""}
              helpText={
                "Any additional information that might help us find resources to design the badge"
              }
              onChange={handleAdditionalInfoChange}
              multiline={4}
            />
          </FormLayout>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </>
  );
});
