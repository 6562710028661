import React, { ReactElement, SVGProps } from 'react'
import { DisplayText, Button, Tooltip } from '@shopify/polaris'
import styled from 'styled-components'

interface Props {
  title: string
  icon: React.FC<SVGProps<SVGSVGElement>>
  onClick(): void
}

const CardHeaderWrapper = styled.div`
display: flex;
align-items: center;
`

const TextWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
flex-grow: 1;
`

const IconWrapper = styled.div`
display: flex;
`

// TODO: Formalise this component

export default function CardHeader({
  title,
  icon,
  onClick
}: Props): ReactElement {

  const handleClick = () => {
    onClick()
  }

  return (
    <CardHeaderWrapper>
      <TextWrapper>
        <DisplayText size={"small"}>{title}</DisplayText>
      </TextWrapper>
      <Tooltip content={"Dismiss"}>
        <IconWrapper>
          <Button onClick={handleClick} plain icon={icon} />
        </IconWrapper>
      </Tooltip>
    </CardHeaderWrapper>
  )
}
