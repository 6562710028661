import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Popover,
  Icon,
  TextField,
  ActionList,
  Stack,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import axios from "axios";
import { TrustBadgesDispatch } from "reducer";

interface FontPickerProps {
  label: string;
  fontSelected: string;
}

interface GoogleFont {
  family: string;
  variants: string[];
  subsets: string[];
  version: string;
  lastModified: string;
  category: string;
}

interface GoogleFontsResponse {
  kind: string;
  items: GoogleFont[];
}

export default React.memo(function FontPicker({
  label,
  fontSelected,
}: FontPickerProps) {
  const dispatch = useContext(TrustBadgesDispatch);
  const [textValue, setTextValue] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fontFamilies, setFontFamilies] = useState<string[]>([]);
  const [fontFamiliesFiltered, setFontFamiliesFiltered] = useState<string[]>(
    []
  );
  const [visibleFontFamiliesIndex, setVisibleFontFamiliesIndex] = useState(0);

  const togglePopover = () => {
    setActive(!active);
  };

  const getGoogleFonts = async () => {
    try {
      setLoading(true);
      const response = await axios.get<GoogleFontsResponse>(
        "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyC7GKAXkAdSeGjltlc5J89-JAyJaP0qVy0&sort=popularity"
      );
      const { items } = response.data;
      const sortedItems = items.slice(0, 300).sort(function (a, b) {
        const fontA = a.family.toLowerCase();
        const fontB = b.family.toLowerCase();
        if (fontA < fontB) return -1;
        if (fontA > fontB) return 1;
        return 0;
      });
      // Returns fonts that have the regular variant. In the future we might support other variants.
      const filteredFonts = sortedItems
        .filter((font) => font.variants.includes("regular"))
        .map((font) => font.family);
      filteredFonts.unshift("Theme's font");
      setFontFamilies(filteredFonts);
      setFontFamiliesFiltered(filteredFonts);
      setVisibleFontFamiliesIndex(filteredFonts.length / 10);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getGoogleFonts();
  }, []);

  const handleClick = (fontFamily: string) => {
    dispatch({
      type: "modifyFontFamily",
      payload: {
        value: fontFamily === "Theme's font" ? "inherit" : fontFamily,
      },
    });
    togglePopover();
  };

  const handleQueryChange = (value: string) => {
    setTextValue(value);
    setFontFamiliesFiltered(
      fontFamilies.filter((fontFamily) =>
        fontFamily.toLowerCase().startsWith(value.toLowerCase())
      )
    );
  };

  const handleQueryClear = () => {
    setTextValue("");
    setFontFamiliesFiltered(fontFamilies);
  };

  const handleScrolledToBottom = () => {
    const totalIndexes = fontFamiliesFiltered.length;
    const interval =
      visibleFontFamiliesIndex + 30 < totalIndexes
        ? 30
        : totalIndexes - visibleFontFamiliesIndex;
    if (interval > 0) {
      setVisibleFontFamiliesIndex(visibleFontFamiliesIndex + interval);
    }
  };

  const fontList = fontFamiliesFiltered.slice(0, visibleFontFamiliesIndex);

  const activator = (
    <Button
      loading={loading}
      onClick={togglePopover}
      icon={
        <span style={{ fontFamily: fontSelected }}>
          {fontSelected === "inherit" ? "Theme's font" : fontSelected}
        </span>
      }
      disclosure={active ? "up" : "down"}
    ></Button>
  );

  return (
    <React.Fragment>
      {!loading &&
        fontFamilies.map((fontFamily) => {
          if (fontFamily !== "Theme's font") {
            return (
              <link
                rel="stylesheet"
                key={fontFamily}
                href={`https://fonts.googleapis.com/css2?family=${fontFamily}&text=${fontFamily}`}
              />
            );
          }
        })}
      <Stack wrap vertical spacing="extraTight">
        <p>{label}</p>
        <Popover
          active={active}
          activator={activator}
          sectioned
          onClose={togglePopover}
        >
          <Popover.Pane fixed>
            <Popover.Section>
              <TextField
                label=""
                placeholder="Filter fonts"
                focused={active}
                prefix={<Icon source={SearchMinor} />}
                value={textValue}
                onChange={handleQueryChange}
                clearButton={true}
                onClearButtonClick={handleQueryClear}
              />
            </Popover.Section>
          </Popover.Pane>

          <Popover.Pane onScrolledToBottom={handleScrolledToBottom}>
            <ActionList
              items={fontList.map((fontFamily) => ({
                content: ((
                  <span style={{ fontFamily }}>{fontFamily}</span>
                ) as unknown) as string,
                onAction: () => handleClick(fontFamily),
              }))}
            />
          </Popover.Pane>
        </Popover>
      </Stack>
    </React.Fragment>
  );
});
