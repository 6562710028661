import React, { useContext } from "react";
import {
  Card,
  Layout,
  Button
} from "@shopify/polaris";
import {
  ViewMajor,
  PaintBrushMajor,
  CircleTickMajor,
  CancelSmallMinor
} from "@shopify/polaris-icons";
import { OnboardingStep } from './components'
import { usePreviewUrl } from "utils";
import { useAppBridge } from "@shopify/app-bridge-react";
import { withAxiosAuth } from 'utils/fetch'
import { useAmplitude } from "react-amplitude-hooks";
import { TrustBadgesDispatch } from "reducer";
import { captureException } from "@sentry/browser";
import { CardHeader } from 'components'

const steps = [
  {
    title: "Customize display",
    icon: PaintBrushMajor,
    description: "Edit the look and feel of your widget to make it truly feel like your own",

  },
  {
    title: "Enable",
    icon: CircleTickMajor,
    description: "Display Trust Badges Pro on your store and start winning your customer's trust"

  },
  {
    title: "View widget",
    icon: ViewMajor,
    // description: "Take a moment to preview the widget to ensure is displayed on the right place",
    description: "View the widget on your store to ensure it has been integrated smoothly."
  },
];

interface Props {
  isDirty: boolean
  isEnabled: boolean
  isDisplayCustomised: boolean
  isWidgetPreviewed: boolean
  onDismiss(): void
}


export default React.memo(function OnboardingSteps({
  isDirty,
  isEnabled,
  isDisplayCustomised,
  isWidgetPreviewed,
  onDismiss
}: Props) {
  const previewUrl = usePreviewUrl()

  const app = useAppBridge()
  const dispatch = useContext(TrustBadgesDispatch)
  const { logEvent } = useAmplitude()
  React.useEffect(() => {
    let didCancel = false
    async function mutateIsDisplayCustomised() {
      if (!didCancel) {
        dispatch({
          type: "toggleOnboarding", payload: { value: { isDisplayCustomised: true } }
        })
        try {
          await withAxiosAuth(app).post('/api/onboarding', { isDisplayCustomised: true })
        } catch (e) {
          captureException(e)
        }

      }
    }
    if ((isDirty && !isDisplayCustomised) || isEnabled) {
      mutateIsDisplayCustomised()
      if (isDirty && !isDisplayCustomised) {
        logEvent("customise display")
      }
    }
    return () => { didCancel = true }
  }, [isDirty, isEnabled])

  const handlePreviewClick = () => {
    if (!isWidgetPreviewed) {
      try {
        dispatch({
          type: "toggleOnboarding", payload: { value: { isWidgetPreviewed: true } }
        })
        withAxiosAuth(app).post('/api/onboarding', { isWidgetPreviewed: true })
        logEvent("complete onboarding")
      } catch (e) {
        captureException(e)
      }
    }
  }

  const handleDismiss = async () => {
    logEvent("close onboarding", { value: "onboarding" })
    onDismiss()
  }

  return (
    <Card>
      <Card.Section>
        <CardHeader title={"Get ready to gain customer's trust. Try these tips to get started."} icon={CancelSmallMinor} onClick={handleDismiss} />
      </Card.Section>
      <Card.Section>
        <Layout>
          <Layout.Section oneThird>
            <OnboardingStep title={steps[0].title} description={steps[0].description} isCompleted={isDisplayCustomised} icon={steps[0].icon} />
          </Layout.Section>
          <Layout.Section oneThird>
            <OnboardingStep title={steps[1].title} description={steps[1].description} isCompleted={isEnabled} icon={steps[1].icon} />
          </Layout.Section>
          <Layout.Section oneThird>
            <OnboardingStep title={isEnabled ? <Button url={previewUrl} onClick={handlePreviewClick} plain monochrome external size={"large"}>{steps[2].title}</Button> : steps[2].title} description={steps[2].description} isCompleted={isWidgetPreviewed} icon={steps[2].icon} />
          </Layout.Section>
        </Layout>
      </Card.Section>
    </Card>
  );
})
