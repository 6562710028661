import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  HSBColor,
  TextField,
  rgbaString,
  hsbToRgb,
  hsbToHex
} from "@shopify/polaris";
import {
  coerceToValidUserInput,
  hexToHsb,
  isHexString
} from "utils/colorUtils";
import styled from "styled-components";

export interface TextPickerProps {
  color: HSBColor;
  allowAlpha?: boolean;
  onChange(hex: string): void;
}

const HuePickerWrapper = styled.div`
  width: 192px;
`;

const TextFieldSwatch = styled.div`
  width: 2rem;
  height: 2rem;
  margin-left: -0.4rem;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 0px 1px inset,
    rgba(0, 0, 0, 0.15) 0px 1px 3px 0px inset;
`;

const Swatch = styled.div`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-shadow: inherit;
`;

export default function TextPicker({
  color,
  allowAlpha,
  onChange
}: TextPickerProps) {
  const [text, setText] = useState("");
  const lastValidValue = useRef("");

  const handleChange = useCallback((value) => {
    setText(value);
  }, []);

  const handleBlur = useCallback(() => {
    const validUserInput = coerceToValidUserInput(text);
    if (validUserInput) {
      setText(validUserInput);
      lastValidValue.current = validUserInput;

      const colorHasChanged = validUserInput !== hsbToHex(color);

      if (colorHasChanged) {
        onChange(validUserInput);
      }

      return;
    }

    setText(lastValidValue.current);
  }, [color, lastValidValue, onChange, text]);

  useEffect(() => {
    const newValue = hsbToHex(color);
    if (newValue !== hsbToHex(hexToHsb(lastValidValue.current))) {
      setText(newValue);
      lastValidValue.current = newValue;
    }
    if (lastValidValue.current === "") lastValidValue.current = newValue;
  }, [color]);

  const valueForDisplay = isHexString(text) ? text.toUpperCase() : text;
  return (
    <HuePickerWrapper>
      <TextField
        label={""}
        value={valueForDisplay}
        onChange={handleChange}
        onBlur={handleBlur}
        prefix={
          <TextFieldSwatch>
            <Swatch style={{ backgroundColor: rgbaString(hsbToRgb(color)) }} />
          </TextFieldSwatch>
        }
      />
    </HuePickerWrapper>
  );
}
