import React, { ReactElement } from "react";
import { Droppable } from "react-beautiful-dnd";
import SortableItem from "./SortableItem";

interface Props {
  iconNames: string[];
  onRemoveBadge(iconName: string): void;
}

export default function SortableList({
  iconNames,
  onRemoveBadge,
}: Props): ReactElement {
  const handleRemoveBadge = (iconName: string) => {
    onRemoveBadge(iconName);
  };

  return (
    <Droppable droppableId={"droppable-selected-icons"}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {iconNames.map((iconName, index) => (
            <SortableItem
              onRemoveBadge={handleRemoveBadge}
              key={iconName}
              iconName={iconName}
              index={index}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
