import React from "react";
import { Stack } from "@shopify/polaris";

interface LabelProps {
  name: string,
  children: React.ReactNode
}

export default function Label({ name, children }: LabelProps) {
  return (
    <Stack wrap vertical spacing="extraTight">
      <p>{name}</p>
      {children}
    </Stack>
  );
}
