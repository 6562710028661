import React from "react";
import {
  TextField,
  Button,
  TextContainer,
  FormLayout,
  Tooltip,
  Stack,
  SkeletonBodyText,
} from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";

export default function PlacementSettingsSkeleton() {
  return (
    <FormLayout>
      <Stack>
        <Stack.Item fill>
          <TextContainer>
            <SkeletonBodyText />
          </TextContainer>
        </Stack.Item>
        <Stack.Item>
          <Button loading>Enabled</Button>
        </Stack.Item>
      </Stack>
      <TextContainer>
        <SkeletonBodyText />
      </TextContainer>
      <TextField
        label={""}
        disabled
        type="text"
        value={`<div class="${(process.env.NEXT_PUBLIC_APP_NAME as string).toLowerCase().split(" ").join("-")}"></div>`}
        connectedRight={
          <Tooltip content="Copy to clipboard">
            <Button disabled icon={ClipboardMinor} />
          </Tooltip>
        }
      />
    </FormLayout>
  );
}
