import React, { useState, useCallback } from "react";
import { Card, EmptyState, Modal, Button } from "@shopify/polaris";

interface Props {
  active: boolean
  onClose(): void
  onAction(): void
}

export default function FiveStarsModal({
  active,
  onClose,
  onAction
}: Props) {

  const handleAction = () => {
    onAction()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title="One more step"
    >
      <Card>
        <Card.Section>
          <EmptyState
            heading="Your feedback means a lot to us!"
            imageContained
            action={{
              content: "Yes. Happy to help",
              external: true, url: `https://apps.shopify.com/${(process.env.NEXT_PUBLIC_APP_NAME as string).toLowerCase().split(" ").join("-")}?utm_source=feedback-modal#modal-show=ReviewListingModal`,
              onAction: handleAction
            }}
            image="/clapping.png"
          >
            Could you help us by writing a quick review on the Shopify App
            Store?
          </EmptyState>
        </Card.Section>
      </Card>
    </Modal>
  );
}
