import {
  Page,
  Card,
  Layout,
  Frame,
  ContextualSaveBar,
  Toast,
  EmptyState,
  DisplayText,
  ComplexAction,
} from "@shopify/polaris";
import React, { useReducer, useEffect, useCallback, useState } from "react";
import {
  BasicSettings,
  BadgeSettings,
  TextSettings,
  SettingToggleExt,
  PlacementSettings,
  PreviewCard,
  Skeleton,
  Onboarding,
} from "components";
import Head from "next/head";
import { reducer, initialState, TrustBadgesDispatch } from "reducer";
import { AnimationEnum, AppSettings } from "types";
import { titleise } from "utils";
import { withAxiosAuth } from "utils/fetch";
import type { ITrustBadgeSettingsSchema } from "models";
import { useAppBridge, Loading } from "@shopify/app-bridge-react";
import { useRouter } from "next/router";
import useSWR from "swr";
import { captureException } from "@sentry/browser";
import { useAmplitude } from "react-amplitude-hooks";

export default function Index() {
  const app = useAppBridge();
  const router = useRouter();
  const { logEvent } = useAmplitude();
  const [state, dispatch] = useReducer(reducer, initialState);
  // TODO - Check if it is suitable to redesign app to use a query library like swr or react-query
  const { data, error } = useSWR<ITrustBadgeSettingsSchema>(
    "/api/setup",
    (url) =>
      withAxiosAuth(app)
        .get(url)
        .then((res) => res.data)
  );
  const [saveToast, setSaveToast] = useState(false);
  const [saveErrorToast, setSaveErrorToast] = useState<{
    enabled: boolean;
    content: string;
    action: ComplexAction | undefined;
  }>({
    enabled: false,
    content: "Server error",
    action: undefined,
  });
  const [discardToast, setDiscardToast] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onUnload = useCallback((e) => {
    // We use useCallback to pass the same reference to add and remove event listeners
    e.preventDefault();
    e.returnValue = "";
  }, []);

  useEffect(() => {
    if (state.contextSettings.dirtyList.length > 0) {
      dispatch({ type: "modifySettings", payload: { isDirty: true } });
      window.addEventListener("beforeunload", onUnload);
    } else {
      window.removeEventListener("beforeunload", onUnload);
      dispatch({ type: "modifySettings", payload: { isDirty: false } });
    }
  }, [state.contextSettings.dirtyList.length]);

  const [previewIcons, setPreviewIcons] = useState<string[]>([]);

  const getPreviewIcons = async () => {
    try {
      const { data } = await withAxiosAuth(app).get<{ icons: string[] }>(
        "/api/previewBadges"
      );
      setPreviewIcons(data.icons);
    } catch (e) {
      captureException(e);
    }
  };

  useEffect(() => {
    getPreviewIcons();
  }, []);

  useEffect(() => {
    if (data) {
      const {
        animation,
        isAutoInsertEnabled,
        isEnabled,
        badgeSettings,
        textSettings,
        onboarding,
      } = data;
      const appSettings: AppSettings = {
        animation: {
          key: 0,
          animation: (animation as unknown) as AnimationEnum,
        },
        badgeSettings: {
          ...badgeSettings,
        },
        textSettings: {
          ...textSettings,
        },
      };
      dispatch({
        type: "setup",
        payload: { isEnabled, isAutoInsertEnabled, appSettings, onboarding },
      });
    }
  }, [data]);

  const handleDiscardChange = () => {
    logEvent("discard changes");
    dispatch({ type: "reset" });
    setDiscardToast(true);
  };

  const handleSaveChange = async () => {
    try {
      const { contextSettings, appSettings } = state; // TODO log important details
      setIsSaving(true);
      await withAxiosAuth(app).post(`/api/save`, {
        isAutoInsertEnabled: contextSettings.isAutoInsertEnabled,
        appSettings: {
          ...appSettings,
          animation: appSettings.animation.animation,
        },
      });
      dispatch({ type: "modifySettings", payload: { isDirty: false } });
      dispatch({ type: "save", payload: { appSettings, contextSettings } });
      setSaveToast(true);
    } catch (e) {
      captureException(e);
      console.log(e.response);
      if (e.response.status === 422) {
        setSaveErrorToast({
          enabled: true,
          content: "Too many icons",
          action: undefined,
        });
      } else {
        setSaveErrorToast({
          enabled: true,
          content: "Server error",
          action: {
            content: "Reload",
            onAction: () => {
              window.location.reload();
            },
          },
        });
      }
    }
    setIsSaving(false);
  };

  const toastSavedMarkup = saveToast && (
    <Toast
      content="Settings saved"
      onDismiss={() => setSaveToast(false)}
      duration={1500}
    />
  );
  const toastDiscardedMarkup = discardToast && (
    <Toast
      content="Settings discarded"
      onDismiss={() => setDiscardToast(false)}
      duration={1500}
    />
  );
  const toastSavedErrorMarkup = saveErrorToast.enabled && (
    <Toast
      duration={10000}
      action={saveErrorToast.action}
      content={saveErrorToast.content}
      error
      onDismiss={() =>
        setSaveErrorToast((prevErrorToast) => ({
          ...prevErrorToast,
          enabled: false,
        }))
      }
    />
  );

  if (error) {
    console.log(error);
    if (error.response.status === 401) {
      // Unauthorised thus redirect to perform Shopify OAuth flow
      const urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams);
      const shop = urlParams.get("shop");
      if (!shop) {
        router.replace("/install");
      }
      window.location.replace(
        `https://${process.env.NEXT_PUBLIC_HOST}/api/auth?shop=${shop}`
      );
    }
    return (
      <EmptyState
        heading="There's a problem loading this page"
        action={{
          content: "Reload Page",
          onAction: () => window.location.reload(),
        }}
        image={"/404.svg"}
      >
        <p>
          There's a technical problem with our server that has prevented this
          page from loading. Try reloading this page or access the app from
          inside Shopify. If that doesn't work, please contact us and we will
          resolve it as soon as we can.
        </p>
      </EmptyState>
    );
  }

  if (!data || !state.appSettings.animation) {
    // If it's loading or no shop param has been provided
    return (
      <>
        <Loading />
        <Page>
          <Skeleton />
        </Page>
      </>
    );
  }

  return (
    <TrustBadgesDispatch.Provider value={dispatch}>
      <Head>
        {state.appSettings.textSettings.fontFamily !== "inherit" && (
          <link
            rel="stylesheet"
            href={`https://fonts.googleapis.com/css2?family=${state.appSettings.textSettings.fontFamily}`}
          />
        )}
      </Head>
      <Frame>
        <Page
          title={
            (
              <DisplayText size="large">
                Welcome to{" "}
                {titleise(process.env.NEXT_PUBLIC_APP_NAME as string)}
              </DisplayText>
            ) as any
          }
        >
          <Onboarding
            onboardingProps={state.contextSettings.onboarding}
            isDirty={state.contextSettings.isDirty}
            isEnabled={state.contextSettings.isEnabled || false}
          />
          {state.contextSettings.isDirty && (
            <ContextualSaveBar
              message="Unsaved changes"
              saveAction={{
                onAction: handleSaveChange,
                loading: isSaving,
                disabled: false,
              }}
              discardAction={{
                onAction: handleDiscardChange,
                discardConfirmationModal: true,
              }}
            />
          )}
          <Layout>
            <Layout.Section fullWidth></Layout.Section>
            <Layout.Section fullWidth>
              <SettingToggleExt
                isEnabled={state.contextSettings.isEnabled || false}
              />
            </Layout.Section>
            <Layout.Section secondary>
              <Card
                title={`${titleise(
                  process.env.NEXT_PUBLIC_APP_NAME as string
                )} Settings`}
                sectioned
              >
                <BasicSettings
                  previewIcons={previewIcons}
                  animation={state.appSettings.animation}
                  textValue={state.appSettings.textSettings.value}
                  selectedBadges={
                    state.appSettings.badgeSettings.selectedBadges
                  }
                  badgeStyle={state.appSettings.badgeSettings.style}
                />
                <TextSettings
                  colour={state.appSettings.textSettings.colour.hsba}
                  size={state.appSettings.textSettings.size}
                  alignment={state.appSettings.textSettings.alignment}
                  format={state.appSettings.textSettings.format}
                  fontFamily={state.appSettings.textSettings.fontFamily}
                  margins={state.appSettings.textSettings.margins}
                />
                <BadgeSettings
                  margins={state.appSettings.badgeSettings.margins}
                  sizeValue={state.appSettings.badgeSettings.size}
                  colorValue={state.appSettings.badgeSettings.colour.hsba}
                  badgeSpacingValue={state.appSettings.badgeSettings.spacing}
                  alignmentValue={state.appSettings.badgeSettings.alignment}
                />
              </Card>
              <PlacementSettings
                isAutoInsertEnabled={
                  state.contextSettings.isAutoInsertEnabled || false
                }
              />
            </Layout.Section>
            <PreviewCard
              {...state.appSettings}
              isEnabled={state.contextSettings.isEnabled}
              isDirty={state.contextSettings.isDirty}
            />
          </Layout>
          {toastSavedMarkup}
          {toastDiscardedMarkup}
          {toastSavedErrorMarkup}
        </Page>
      </Frame>
    </TrustBadgesDispatch.Provider>
  );
}
// Force commit
