import React, { useState } from "react";
import { Card, Layout, Stack, TextStyle } from "@shopify/polaris";
import { CancelSmallMinor } from "@shopify/polaris-icons";
import { CardHeader } from "components";
import { Rating, IconContainerProps } from "@material-ui/lab";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import styles from "./ReviewRequest.module.css";
import { useAppBridge } from "@shopify/app-bridge-react";
import { useAmplitude } from "react-amplitude-hooks";
import { FeedbackModal, FiveStarsModal } from "./components";
import { withAxiosAuth } from "utils/fetch";
import { CustomToastProps } from "../../Onboarding";

const customIcons: {
  [index: string]: { icon: React.ReactElement; label: string };
} = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon
        classes={{ fontSizeLarge: styles.fontSizeLarge }}
        fontSize={"large"}
      />
    ),
    label: "Dislike it",
  },
  2: {
    icon: (
      <SentimentDissatisfiedIcon
        classes={{ fontSizeLarge: styles.fontSizeLarge }}
        fontSize={"large"}
      />
    ),
    label: "Not great",
  },
  3: {
    icon: (
      <SentimentSatisfiedIcon
        classes={{ fontSizeLarge: styles.fontSizeLarge }}
        fontSize={"large"}
      />
    ),
    label: "Okay",
  },
  4: {
    icon: (
      <SentimentSatisfiedAltIcon
        classes={{ fontSizeLarge: styles.fontSizeLarge }}
        fontSize={"large"}
      />
    ),
    label: "Like it",
  },
  5: {
    icon: (
      <SentimentVerySatisfiedIcon
        classes={{ fontSizeLarge: styles.fontSizeLarge }}
        fontSize={"large"}
      />
    ),
    label: "Love it!",
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return (
    <span className={"largeReview"} {...other}>
      {customIcons[value].icon}
    </span>
  );
}

interface Props {
  onDismiss(toastProps?: CustomToastProps): void;
}

export default function ReviewRequest({ onDismiss }: Props) {
  const { logEvent } = useAmplitude();
  const [feedbackReqModal, setFeedbackReqModal] = useState(false);
  const [fiveStarsModal, setFiveStarsModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [hover, setHover] = useState(-1);
  const app = useAppBridge();
  const handleDismiss = async () => {
    logEvent("close onboarding", { value: "review" });
    onDismiss();
  };

  const handleModalClose = () => {
    logEvent("close feedback request");
    setRating(0);
    setFeedbackReqModal(false);
  };

  const handleFiveStarsModalClose = () => {
    logEvent("close five stars modal");
    setRating(0);
    setFiveStarsModal(false);
  };

  const handleModalSubmit = async (value: string) => {
    logEvent("send feedback request", { rating, value });
    setSubmitLoading(true);
    await withAxiosAuth(app).post("/api/feedback", {
      rating,
      description: value,
    });
    setSubmitLoading(false);
    onDismiss({ value: "Feedback sent", undo: false, active: true });
    setFeedbackReqModal(false);
  };

  const handleFiveStarsAction = () => {
    logEvent("click 5 star review link");
    onDismiss({ value: "", undo: false, active: false });
    setFiveStarsModal(false);
  };

  const handleRatingClick = (
    event: React.ChangeEvent<{}>,
    value?: number | null
  ) => {
    logEvent("rate app", { value });
    if (value) {
      setRating(value);
      value < 5 ? setFeedbackReqModal(true) : setFiveStarsModal(true);
    } else {
      setRating(0);
    }
  };

  let labelValue: string;

  if (rating === 0) {
    labelValue = "Rate your experience!";
  }

  return (
    <Card>
      <Card.Section>
        <CardHeader
          title={"Good job. You're all set!"}
          icon={CancelSmallMinor}
          onClick={handleDismiss}
        />
      </Card.Section>
      <Card.Section>
        <Layout>
          <Layout.Section oneHalf>
            <TextStyle>
              We truly value your opinion as it helps us improve the app and
              also hear what you love about it. Let us know your experience with
              Trust Badges Pro.
            </TextStyle>
          </Layout.Section>
          <Layout.Section secondary>
            <Stack vertical alignment={"center"}>
              <div className={styles.rating}>
                <Rating
                  name={"request-feedback"}
                  getLabelText={(value: number) => customIcons[value].label}
                  IconContainerComponent={IconContainer}
                  onChange={handleRatingClick}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  value={rating}
                  size={"large"}
                />
              </div>
              <Stack.Item fill>
                <TextStyle>
                  {rating === 0 && hover === -1
                    ? "Rate your experience"
                    : customIcons[hover !== -1 ? hover : rating].label}
                </TextStyle>
              </Stack.Item>
            </Stack>
            <FeedbackModal
              active={feedbackReqModal}
              onClose={handleModalClose}
              onSubmit={handleModalSubmit}
              submitLoading={submitLoading}
            />
            <FiveStarsModal
              active={fiveStarsModal}
              onClose={handleFiveStarsModalClose}
              onAction={handleFiveStarsAction}
            />
          </Layout.Section>
        </Layout>
      </Card.Section>
    </Card>
  );
}
