import React from "react";
import {
  Card,
  FormLayout,
  RangeSlider,
  ButtonGroup,
  Button,
  Stack,
} from "@shopify/polaris";
import {
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignLeft,
} from "@material-ui/icons";
import { Label } from "components";


export default function BadgeSettingsSkeleton() {
  return (
    <Card.Section title="Badges">
      <FormLayout>
        <FormLayout.Group condensed>
          <Label name="Color">
            <Button icon={
              <Stack alignment="center" spacing="tight">
              <div
                style={{
                  height: "2.5rem",
                  width: "5.5rem",
                  borderRadius: "3px",
                  boxShadow:
                    "inset 0 0 0 1px rgba(0,0,0,.07), inset 0 1px 3px 0 rgba(0,0,0,.15)",
                }}
              />
            </Stack>
            } loading>
            </Button>
          </Label>
          <RangeSlider label="Size" disabled suffix="px" max={150} output value={30} onChange={() => {}} />
        </FormLayout.Group>
        <FormLayout.Group condensed>
        </FormLayout.Group>
        <Label name="Alignment">
          <ButtonGroup segmented fullWidth>
            <Button disabled icon={<FormatAlignLeft />} />
            <Button disabled icon={<FormatAlignCenter />} />
            <Button disabled icon={<FormatAlignRight />} />
          </ButtonGroup>
        </Label>
        <RangeSlider
          label="Spacing"
          suffix="px"
          max={150}
          disabled
          output
          value={30}
          onChange={() => {}}
        />
      </FormLayout>
    </Card.Section>
  )
}
