import * as React from "react"
import {
  Modal,
  TextField
} from "@shopify/polaris"

interface Props {
  active: boolean
  onClose(): void
  submitLoading: boolean
  onSubmit(value: string): Promise<void>
}

export default function FeedbackModal({
  active,
  onClose,
  onSubmit,
  submitLoading,
 }: Props) {
  const [value, setValue] = React.useState("")

  const handleChange = React.useCallback((newValue) => setValue(newValue), [])
  const handleSubmit = async () => {
    await onSubmit(value)
    setValue("")
  }

  const handleClose = () => {
    setValue("")
    onClose()
  }

  return (
      <Modal
        large={false}
        open={active}
        onClose={handleClose}
        title="Help us get better"
        primaryAction={{
          content: "Send feedback",
          loading: submitLoading,
          onAction: handleSubmit,
          disabled: !value
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleClose
          }
        ]}
      >
        <Modal.Section>
          <TextField
            label="Describe your experience"
            placeholder={""}
            value={value}
            helpText={
              "We take your feedback very seriously as it helps us improve our app for you and other users."
            }
            onChange={handleChange}
            multiline={4}
          />
        </Modal.Section>
      </Modal>
  )
}
