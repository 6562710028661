import React, { useContext } from "react";
import {
  Card,
  FormLayout,
  RangeSlider,
  HSBAColor,
  Button,
  ButtonGroup,
  TextField,
} from "@shopify/polaris";
import {
  Accordion,
  ColorPickerExt,
  Label,
  CardCustomSubsection,
} from "components";
import {
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignLeft,
} from "@material-ui/icons";
import { TrustBadgesDispatch } from "reducer";
import { Alignment, Margins } from "types";
import { useGroupSelect } from "utils";

interface BadgeSettingsProps {
  colorValue: HSBAColor;
  sizeValue: number;
  alignmentValue: Alignment;
  badgeSpacingValue: number;
  margins: Margins;
}

export default React.memo(function BadgeSettings({
  colorValue,
  sizeValue,
  alignmentValue,
  badgeSpacingValue,
  margins,
}: BadgeSettingsProps) {
  const dispatch = useContext(TrustBadgesDispatch);
  const [selectedAlignment, handleSelectedAlignment] = useGroupSelect<
    Alignment
  >({ isMulti: false, defaultValue: [alignmentValue] });

  const handleSize = (value: number) => {
    dispatch({
      type: "modifyBadgeSize",
      payload: { value },
    });
  };

  const handleColor = React.useCallback((hsba: HSBAColor) => {
    dispatch({
      type: "modifyBadgeColor",
      payload: { value: { hsba } },
    });
  }, []);

  React.useEffect(() => {
    dispatch({
      type: "modifyBadgeAlignment",
      payload: { value: selectedAlignment[0] },
    });
  }, [selectedAlignment]);

  const handleSpacingChange = React.useCallback((value: number) => {
    dispatch({
      type: "modifyBadgeSpacing",
      payload: { value },
    });
  }, []);

  const handleMarginChange = (value: string, position: keyof Margins): void => {
    dispatch({
      type: "modifyBadgeMargins",
      payload: {
        value: {
          ...margins,
          [position]: +value,
        },
      },
    });
  };

  return (
    <Card.Section title="Badges">
      <FormLayout>
        <FormLayout.Group condensed>
          <ColorPickerExt
            onChange={handleColor}
            label="Color"
            color={colorValue}
          />
          <RangeSlider
            label="Size"
            value={sizeValue}
            onChange={handleSize}
            suffix={`${sizeValue}px`}
            min={10}
            max={150}
          />
        </FormLayout.Group>
        <Label name={"Alignment"}>
          <ButtonGroup segmented fullWidth>
            <Button
              primary={selectedAlignment.includes("left")}
              onClick={() => handleSelectedAlignment("left")}
              icon={<FormatAlignLeft />}
            />
            <Button
              primary={selectedAlignment.includes("center")}
              onClick={() => handleSelectedAlignment("center")}
              icon={<FormatAlignCenter />}
            />
            <Button
              primary={selectedAlignment.includes("right")}
              onClick={() => handleSelectedAlignment("right")}
              icon={<FormatAlignRight />}
            />
          </ButtonGroup>
        </Label>
        <RangeSlider
          label="Spacing"
          value={badgeSpacingValue}
          onChange={handleSpacingChange}
          suffix={`${badgeSpacingValue}px`}
          max={150}
        />
        <Accordion
          id="badges-advanced-settings-accordion"
          buttonText="Advanced settings"
          buttonProps={{ plain: true }}
        >
          <CardCustomSubsection title={"Margins"}>
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField
                  id="top"
                  label="Top"
                  type="number"
                  value={margins.top.toString()}
                  onChange={handleMarginChange}
                  suffix={"px"}
                />
                <TextField
                  id="right"
                  label="Right"
                  type="number"
                  value={margins.right.toString()}
                  onChange={handleMarginChange}
                  suffix={"px"}
                />
                <TextField
                  id="bottom"
                  label="Bottom"
                  type="number"
                  value={margins.bottom.toString()}
                  onChange={handleMarginChange}
                  suffix={"px"}
                />
                <TextField
                  id="left"
                  label="Left"
                  type="number"
                  value={margins.left.toString()}
                  onChange={handleMarginChange}
                  suffix={"px"}
                />
              </FormLayout.Group>
            </FormLayout>
          </CardCustomSubsection>
        </Accordion>
      </FormLayout>
    </Card.Section>
  );
});
