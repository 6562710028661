import React, { ReactElement, useContext, useState } from 'react'
import { IOnboarding } from 'types'
import { Action, Toast } from '@shopify/polaris'
import { OnboardingSteps, ReviewRequest } from './components'
import { withAxiosAuth } from 'utils/fetch'
import { captureException } from '@sentry/browser'
import { TrustBadgesDispatch } from 'reducer'
import { useAppBridge } from '@shopify/app-bridge-react'

interface Props {
  isDirty: boolean
  isEnabled: boolean
  onboardingProps: IOnboarding
}

export interface CustomToastProps {
  active: boolean
  value: string
  undo: boolean
}

export default function Onboarding({
  isDirty,
  isEnabled,
  onboardingProps
}: Props): ReactElement | null {

  const [toast, setToast] = useState<CustomToastProps>({ active: false, value: "Onboarding dismissed", undo: true })
  const dispatch = useContext(TrustBadgesDispatch)
  const app = useAppBridge()

  const handleAction = async (toastProps: CustomToastProps | boolean = { active: true, value: "Onboarding dismissed", undo: true }, isOnboardingClosed: boolean = true) => {
    let newToastProps: CustomToastProps
    if (typeof toastProps === "boolean") {
      newToastProps = { ...toast, active: toastProps }
    } else {
      newToastProps = toastProps
    }
    setToast(newToastProps)
    dispatch({
      type: "toggleOnboarding", payload: { value: { isOnboardingClosed } }
    })
    try {
      await withAxiosAuth(app).post('/api/onboarding', { isOnboardingClosed })
    } catch (e) {
      captureException(e)
    }
  }

  const toastAction: Action = toast.undo ? ({ content: "Undo", onAction: () => handleAction(false, false) }) : {}

  const toastMarkup = toast.active && toast.value ? (
    <Toast content={toast.value} action={toastAction} onDismiss={() => setToast(prevToast => ({ ...prevToast, active: false }))} />
  ) : null


  if (onboardingProps.isOnboardingClosed) return toastMarkup

  if (onboardingProps.isDisplayCustomised && onboardingProps.isWidgetPreviewed) {
    return <ReviewRequest onDismiss={handleAction} />
  }

  return (
    <OnboardingSteps
      isDirty={isDirty}
      isDisplayCustomised={onboardingProps.isDisplayCustomised}
      isWidgetPreviewed={onboardingProps.isWidgetPreviewed}
      isEnabled={isEnabled}
      onDismiss={handleAction}
    />
  )
}
