import React, { useState } from "react";
// Test commit 0
import {
  Button,
  ButtonProps,
  Collapsible,
  Stack,
} from "@shopify/polaris";

interface AccordionProps {
  id: string,
  children: React.ReactNode,
  buttonProps: ButtonProps,
  buttonText: ButtonProps["children"],
  withDisclousre?: boolean,
}

export default function Accordion({
  id = "basic-collapsible",
  children,
  buttonProps,
  buttonText,
  withDisclousre = true,
}: AccordionProps) {
  const [open, setOpen] = useState(false);

  const handleToggleClick = () => {
    setOpen(!open);
  };

  return (
      <>
          <Button
            {...buttonProps}
            onClick={handleToggleClick}
            ariaExpanded={open}
            ariaControls={id}
            disclosure={withDisclousre ? open ? "up" : "down" : false}
          >
            {buttonText}
        </Button>
          <Collapsible open={open} id={id}>
            {children}
          </Collapsible>
      </>
    );
}
