import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  FormLayout,
  RangeSlider,
  HSBAColor,
  Button,
  ButtonGroup,
  TextField,
} from "@shopify/polaris";
import {
  ColorPickerExt,
  FontPicker,
  Accordion,
  Label,
  CardCustomSubsection,
} from "components";
import {
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignLeft,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
} from "@material-ui/icons";
import { TrustBadgesDispatch } from "reducer";
import { Alignment, TextFormat, Margins, StylePosition } from "types";
import { useGroupSelect } from "utils";
import styled from "styled-components";

const CardContainer = styled.div`
  padding-left: 0;
  padding-right: 0;
`;

interface TextSettingsProps {
  colour: HSBAColor;
  size: number;
  alignment: Alignment;
  format: TextFormat[];
  fontFamily: string;
  margins: Margins;
}

function TextSettings({
  colour,
  size,
  alignment,
  format,
  fontFamily,
  margins,
}: TextSettingsProps) {
  const dispatch = useContext(TrustBadgesDispatch);
  const [selectedAlignment, handleSelectedAlignment] = useGroupSelect<
    Alignment
  >({ isMulti: false, defaultValue: [alignment] });
  const [selectedFormatValue, handleSelectedFormatValue] = useGroupSelect<
    TextFormat
  >({ isMulti: true, defaultValue: format });

  const handleColor = React.useCallback((hsba: HSBAColor) => {
    dispatch({
      type: "modifyTextColor",
      payload: { value: { hsba } },
    });
  }, []);
  const handleSizeChange = React.useCallback((value: number) => {
    dispatch({ type: "modifyTextSize", payload: { value } });
  }, []);

  React.useEffect(() => {
    dispatch({
      type: "modifyTextAlignment",
      payload: { value: selectedAlignment[0] },
    });
  }, [selectedAlignment]);

  React.useEffect(() => {
    dispatch({
      type: "modifyTextFormat",
      payload: { value: selectedFormatValue },
    });
  }, [selectedFormatValue]);

  const handleMarginChange = (value: string, position: keyof Margins): void => {
    dispatch({
      type: "modifyTextMargins",
      payload: {
        value: {
          ...margins,
          [position]: +value,
        },
      },
    });
  };

  return (
    <Card.Section title="Text">
      <FormLayout>
        <FormLayout.Group condensed>
          <ColorPickerExt onChange={handleColor} label="Color" color={colour} />
          <RangeSlider
            label="Size"
            value={size}
            onChange={handleSizeChange}
            suffix={`${size}px`}
            min={10}
            max={50}
          />
        </FormLayout.Group>
        <FormLayout.Group condensed>
          <FontPicker fontSelected={fontFamily} label="Font Family" />
          <Label name={"Format"}>
            <ButtonGroup segmented>
              <Button
                primary={selectedFormatValue.includes("bold")}
                onClick={() => handleSelectedFormatValue("bold")}
                icon={<FormatBold />}
              />
              <Button
                primary={selectedFormatValue.includes("italic")}
                onClick={() => handleSelectedFormatValue("italic")}
                icon={<FormatItalic />}
              />
              <Button
                primary={selectedFormatValue.includes("underline")}
                onClick={() => handleSelectedFormatValue("underline")}
                icon={<FormatUnderlined />}
              />
            </ButtonGroup>
          </Label>
        </FormLayout.Group>
        <Label name={"Alignment"}>
          <ButtonGroup segmented fullWidth>
            <Button
              primary={selectedAlignment.includes("left")}
              onClick={() => handleSelectedAlignment("left")}
              icon={<FormatAlignLeft />}
            />
            <Button
              primary={selectedAlignment.includes("center")}
              onClick={() => handleSelectedAlignment("center")}
              icon={<FormatAlignCenter />}
            />
            <Button
              primary={selectedAlignment.includes("right")}
              onClick={() => handleSelectedAlignment("right")}
              icon={<FormatAlignRight />}
            />
          </ButtonGroup>
        </Label>
        <Accordion
          id="text-advanced-settings-accordion"
          buttonText="Advanced settings"
          buttonProps={{ plain: true }}
        >
          <CardCustomSubsection title={"Margins"}>
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField
                  id="top"
                  label="Top"
                  type="number"
                  value={margins.top.toString()}
                  onChange={handleMarginChange}
                  suffix={"px"}
                />
                <TextField
                  id="right"
                  label="Right"
                  type="number"
                  value={margins.right.toString()}
                  onChange={handleMarginChange}
                  suffix={"px"}
                />
                <TextField
                  id="bottom"
                  label="Bottom"
                  type="number"
                  value={margins.bottom.toString()}
                  onChange={handleMarginChange}
                  suffix={"px"}
                />
                <TextField
                  id="left"
                  label="Left"
                  type="number"
                  value={margins.left.toString()}
                  onChange={handleMarginChange}
                  suffix={"px"}
                />
              </FormLayout.Group>
            </FormLayout>
          </CardCustomSubsection>
        </Accordion>
      </FormLayout>
    </Card.Section>
  );
}

export default React.memo(TextSettings);
