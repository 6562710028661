import React, { useState, useContext } from "react";
import { SettingToggle, TextStyle, Toast } from "@shopify/polaris";
import { TrustBadgesDispatch } from 'reducer'
import { titleise } from "utils";
import { withAxiosAuth } from "utils/fetch"
import { useAppBridge } from "@shopify/app-bridge-react";
import { captureException } from '@sentry/browser'
import { useAmplitude } from "react-amplitude-hooks";
interface SettingToggleExtProps {
  isEnabled: boolean,
}

export default React.memo(function SettingToggleExt({
  isEnabled,
}: SettingToggleExtProps) {
  const app = useAppBridge()
  const { logEvent } = useAmplitude()
  const dispatch = useContext(TrustBadgesDispatch)
  const [buttonLoading, setButtonLoading] = useState(false);
  const [saveError, setSaveError] = useState(false)
  const handleChange = async () => {
    setButtonLoading(true);
    try {
      const newIsEnabled = !isEnabled
      await withAxiosAuth(app).post(`/api/toggle`, { isEnabled: newIsEnabled });
      dispatch({
        type: "toggleIsEnabled",
      });
      logEvent("toggle setting", { value: newIsEnabled })
    } catch (err) {
      captureException(err)
      setSaveError(true)
    }
    setButtonLoading(false);
  };

  const contentStatus = isEnabled ? "Disable" : "Enable";
  const textStatus = (
    <TextStyle variation="strong">
      {isEnabled ? "enabled" : "disabled"}
    </TextStyle>
  );
  const additionalText = `Your customers ${isEnabled ? "can" : "won't"
    } see the widget on your store`;

  const toastEnableErrorMarkup = saveError && (
    <Toast
      duration={10000}
      action={{
        content: "Reload",
        onAction: () => {
          window.location.reload();
        },
      }}
      content="Server Error"
      error
      onDismiss={() => setSaveError(false)}
    />
  );

  return (
    <>
      <SettingToggle
        action={{
          content: contentStatus,
          onAction: handleChange,
          loading: buttonLoading,
        }}
        enabled={isEnabled}
      >
        {titleise(process.env.NEXT_PUBLIC_APP_NAME as string)} is {textStatus}. {additionalText}
      </SettingToggle>
      {saveError && toastEnableErrorMarkup}
    </>
  );
})
