import React, { ReactElement } from 'react'
import {Card, EmptySearchResult} from '@shopify/polaris'

export function BadgesEmptyState(): ReactElement {
  return (
    <Card.Subsection>
      <EmptySearchResult
      title="We couldn't find any badges 😞"
      description="Request a badge from us or try a different search"
      withIllustration
       />
    </Card.Subsection>
  )
}
