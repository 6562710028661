import React, { InputHTMLAttributes } from 'react'
import { Tooltip } from '@shopify/polaris'
import styles from './CardBadge.module.css';
import { captionise } from 'utils'
import { SVGIconProps } from 'types'
import dynamic from 'next/dynamic';

interface CardBadgeProps {
  id: string,
  checked: boolean,
  caption: string,
  onChange(id: string, isChecked: boolean): void
}

const CardBadge = ({ id, checked, caption, onChange }: CardBadgeProps) => {
  const handleChange = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    onChange(id, !checked)
  }

  const Icon = React.useMemo(() => dynamic<SVGIconProps>(() => import(`icons/${id}.svg`)), [])

  return (
    <label onClick={handleChange}>
      <span style={{ position: "absolute" }} className="Polaris-Choice__Control">
        <span className="Polaris-Checkbox Polaris-Checkbox--newDesignLanguage">
          <input
            name={id}
            checked={checked}
            onChange={() => {}}
            type="checkbox"
            className="Polaris-Checkbox__Input"
            aria-invalid="false"
            role="checkbox"
            aria-checked="false"
            value=""
          />
          <span className={`Polaris-Checkbox__Backdrop ${styles.Checkbox} ${styles.CheckboxCenter}`}></span>
        </span>
      </span>
      <span className={styles.Tooltip}>
        <Tooltip content={captionise(caption)}>
          <label
            className={`Polaris-Choice ${styles.Checkbox} ${styles.CheckboxCenter}`}
            htmlFor={id}
          >
            <div className={styles.BadgeContainer}>
              <Icon width={60} height={60} />
            </div>

          </label>
        </Tooltip>
      </span>
    </label>
  )
}

function areEqual(prevProps: CardBadgeProps, nextProps: CardBadgeProps) {
  return prevProps.checked === nextProps.checked
}

export default React.memo(CardBadge, areEqual)
