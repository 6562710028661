import React from "react";
import {
  Page,
  Card,
  Layout,
  SkeletonBodyText,
  DisplayText,
} from "@shopify/polaris";
import {
  TextSettingsSkeleton,
  BasicSettingsSkeleton,
  BadgeSettingsSkeleton,
  PreviewCardSkeleton,
  PlacementSettingsSkeleton,
} from "./components";
import { titleise } from "utils";

export default function Skeleton() {
  return (
    <Page
      title={
        (
          <DisplayText size="large">
            Welcome to {titleise(process.env.NEXT_PUBLIC_APP_NAME as string)}
          </DisplayText>
        ) as any
      }
    >
      <Layout>
        <Layout.Section fullWidth>
          <Card sectioned>
            <SkeletonBodyText lines={1} />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card title="Settings" sectioned>
            <BasicSettingsSkeleton />
            <TextSettingsSkeleton />
            <BadgeSettingsSkeleton />
          </Card>
          <Card title="Placement Settings" sectioned>
            <PlacementSettingsSkeleton />
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <PreviewCardSkeleton />
        </Layout.Section>
        <Layout.Section oneThird></Layout.Section>
      </Layout>
    </Page>
  );
}
