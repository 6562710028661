import React, { useRef, useState } from "react";
import {
  Popover,
  Button,
  Stack,
  ColorPicker,
  hsbToRgb,
  rgbString,
  HSBAColor
} from "@shopify/polaris";
import { Label } from 'components'
import { TextPicker } from "./components";
import { hexToHsb } from "utils/colorUtils";

interface ColorPickerExtProps {
  label: string;
  color: HSBAColor;
  onChange(hsbaColor: HSBAColor): void;
}

export default React.memo(function ColorPickerExt({
  label,
  color,
  onChange
}: ColorPickerExtProps) {
  const [active, setActive] = useState(false);

  const handleColorChange = (hsbaColor: HSBAColor) => {
    onChange(hsbaColor);
  };

  const togglePopover = () => {
    setActive(!active);
  };

  const handleTextChange = (hex: string) => {
    const newColor = hexToHsb(hex);
    onChange({ ...newColor, alpha: 1 });
  };

  const activator = (
    <Button
      icon={
        <Stack alignment="center" spacing="tight">
          <div
            style={{
              height: "2.5rem",
              width: "5.5rem",
              borderRadius: "3px",
              boxShadow:
                "inset 0 0 0 1px rgba(0,0,0,.07), inset 0 1px 3px 0 rgba(0,0,0,.15)",
              background: rgbString(hsbToRgb(color))
            }}
          />
        </Stack>
      }
      onClick={togglePopover}
    ></Button>
  );

  return (
    <Label name={label}>
      <Popover
        active={active}
        sectioned
        activator={activator}
        onClose={togglePopover}
      >
        <Stack vertical spacing={"tight"}>
          <ColorPicker onChange={handleColorChange} color={color} />
          <TextPicker onChange={handleTextChange} color={color} />
        </Stack>
      </Popover>
    </Label>
  );
});
